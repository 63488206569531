import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { useTransactionId } from './useTransactionId';
import { ApiError } from '../../../../../../../shared/http/ApiError';
import { useUserPocket } from '../../../../../../hooks/pocket/queries/useUserPocket';
import { useApiClient } from '../../../../../../providers/ApiClientProvider/context';
import { useStreaksUserState } from '../../../../../../hooks/streaks/useStreaksUserState';
import { useUTCToday } from '../../../../../../hooks/dates/useUTCToday';
import { useHapticFeedback } from '../../../../../../hooks/useHapticFeedback';

export function useToolkit() {
  const client = useApiClient();
  const [transactionId, { set: setTransactionId }] = useTransactionId();
  const userPocket = useUserPocket();
  const userStreakState = useStreaksUserState();
  const today = useUTCToday();
  const { t } = useTranslation();
  const haptic = useHapticFeedback();

  const convertLivesToTx = useSWRMutation(
    'CONVERT_LIVES_TO_TX',
    (_, { arg }: { arg: number }) => client.livesToTransaction(arg),
    {
      onError(err) {
        console.error('Error converting lives to transaction', err);

        // Our local state desynced with the server one for some reason.
        if (err instanceof ApiError && err.hasErrorCode('ERROR_NOT_ENOUGH_FUNDS')) {
          haptic.notificationOccurred('error');
          window.Telegram.WebApp.showAlert(t('StreaksPage.InsufficientLivesToRestore'));
          void userPocket.mutate();
        }
      },
      onSuccess(data) {
        setTransactionId(data.transactionId);
      },
    },
  );

  useSWR(
    transactionId.data ? ['RESTORE_STREAK', transactionId.data] : null,
    ([, transactionId]) => client
      .restoreStreak(transactionId)
      .then(() => true)
      .catch(e => {
        if (e instanceof ApiError && e.hasErrorCode('ERROR_STATUS_PENDING')) {
          return false;
        }
        throw e;
      }),
    {
      refreshInterval: transactionId.data ? 2000 : 0,
      onError(err) {
        if (err instanceof ApiError && err.hasOneOfErrorCodes([
          'ERROR_STATUS_UNKNOWN',
          'ERROR_STATUS_FAILED',
          'ERROR_STATUS_CORRUPTED',
          'ERROR_TRANSACTION_META_CORRUPTED',
          'ERROR_WRONG_TRANSACTION_TYPE',
          'ERROR_TRANSACTION_ALREADY_APPLIED',
        ])) {
          // These error codes are considered as dead for transaction.
          setTransactionId(null);
          haptic.notificationOccurred('error');
          // TODO: Alert?
        }
      },
      onSuccess(completed) {
        if (completed) {
          setTransactionId(null);
          void userStreakState.mutate(
            userStreakState.data
              ? {
                ...userStreakState.data,
                latestCompletedTaskDate: new Date(today - 24 * 60 * 60 * 1000),
              }
              : undefined,
          );
          haptic.notificationOccurred('success');
        }
      },
    },
  );

  return {
    isLoading: convertLivesToTx.isMutating || !!transactionId.data,
    restoreStreak(livesToSell: number): void {
      convertLivesToTx.trigger(livesToSell);
    },
  };
}