import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import type { ReactNode } from 'react';

import { Body, Callout, Heading } from '../../../uilib/Typography/Typography';
import { createSrcSet } from '../../../utils/createSrcSet';
import { publicUrl } from '../../../utils/url';
import { SystemRipple } from '../SystemRipple/SystemRipple';
import {
  useRedPacketUnopenedPacketsCount,
} from '../../hooks/red-packet/useRedPacketUnopenedPacketsCount';
import { TextWithIcon } from '../TextWithIcon';
import { DefaultShimmer } from '../DefaultShimmer/DefaultShimmer';
import {
  useCloudStorageKey,
  useIsCloudStorageLoading,
} from '../../providers/CloudStorageProvider/context';
import { DefaultAnimatePresence } from '../DefaultAnimatePresence';
import { StreaksButton } from '../StreaksButton/StreaksButton';

import { ReactComponent as CloseRoundedIcon } from '../../../svg/img/close-circle.svg';
import s from './RedPacketOverWidget.module.scss';

function Loading() {
  return (
    <div className={s.shimmer}>
      <DefaultShimmer width={'70%'} height={24} mb={4}/>
      <DefaultShimmer width={'90%'} height={20} mb={4}/>
      <DefaultShimmer width={'60%'} height={20}/>
    </div>
  );
}

function Loaded({ hasUnopenedPackets }: { hasUnopenedPackets: boolean }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setWidgetHidden] = useCloudStorageKey('redPacketOverWidgetHidden');

  return (
    <div className={s.root}>
      <div className={s.inner}>
        <div className={cn(s.header, !hasUnopenedPackets && s.headerWithAction)}>
          <Heading weight="bold" type="h4">
            <TextWithIcon
              icon={
                <img
                  className={s.headerIcon}
                  alt=""
                  src={publicUrl('/red-packet/icon/icon.png')}
                  srcSet={createSrcSet(['', '@2x', '@3x'].map(suffix => {
                    return publicUrl(`/red-packet/icon/icon${suffix}.png`);
                  }))}
                  width={24}
                  height={24}
                />
              }
            >
              {t(hasUnopenedPackets
                ? 'RedPacketOverWidget.TitleUnopened'
                : 'RedPacketOverWidget.Title')}
            </TextWithIcon>
          </Heading>
          {!hasUnopenedPackets && (
            <SystemRipple
              className={s.action}
              onClick={() => {
                setWidgetHidden('1');
              }}
            >
              <CloseRoundedIcon className={s.actionIcon}/>
            </SystemRipple>
          )}
        </div>
        <div className={cn(s.body, hasUnopenedPackets && s.bodyHasUnfinished)}>
          <Callout className={s.text} weight="regular">
            {t(hasUnopenedPackets ? 'RedPacketOverWidget.TextUnopened' : 'RedPacketOverWidget.Text')}
          </Callout>
          {hasUnopenedPackets && (
            <StreaksButton
              className={s.button}
              onClick={() => {
                navigate('/red-packet')
              }}
            >
              <Body weight="semi-bold">
                {t('RedPacketOverWidget.ButtonText')}
              </Body>
            </StreaksButton>
          )}
        </div>
      </div>
    </div>
  );
}

export function RedPacketOverWidget({ wrapContent }: {
  wrapContent?: (node: ReactNode) => ReactNode,
}) {
  const unopenedPacketsCount = useRedPacketUnopenedPacketsCount();
  const [value] = useCloudStorageKey('redPacketOverWidgetHidden');
  const isCSLoading = useIsCloudStorageLoading();
  const content = unopenedPacketsCount === 'loading' || isCSLoading
    ? <Loading/>
    : <Loaded hasUnopenedPackets={!!unopenedPacketsCount}/>;

  return (
    <DefaultAnimatePresence
      initial={{ marginTop: -12 }}
      animate={{ marginTop: 0 }}
    >
      {!isCSLoading && value ? null : (
        <motion.div>
          {wrapContent ? wrapContent(content) : content}
        </motion.div>
      )}
    </DefaultAnimatePresence>
  );
}