import { Trans } from 'react-i18next';
import type { PropsWithChildren } from 'react';
import cn from 'classnames';

import { Footnote } from '../../../../uilib/Typography/Typography';
import { ChipButton } from '../ChipButton/ChipButton';

import s from './StreakArtifacts.module.scss';

interface DataProps {
  points: number;
  lives: number;
  /**
   * True, if this component is displayed solely without web3 artifacts.
   */
  standalone?: boolean;
}

function Container({ children, standalone }: PropsWithChildren<{ standalone?: boolean }>) {
  return (
    <div className={cn(s.root, standalone && s.rootStandalone)}>
      <Footnote className={s.title} weight="semi-bold" caps>
        <Trans i18nKey="PocketPage.StreakArtifactsTitle"/>
      </Footnote>
      <div className={s.items}>
        {children}
      </div>
    </div>
  );
}

function Shimmers() {
  return (
    <Container>
      <ChipButton data="loading"/>
      <ChipButton data="loading"/>
    </Container>
  );
}

function Data({ points, lives, standalone }: DataProps) {
  return (
    <Container standalone={standalone}>
      <ChipButton
        data={{
          title: points,
          subtitle: <Trans i18nKey="PocketPage.StreakArtifactsPts" count={points}/>,
        }}
      />
      <ChipButton
        data={{
          title: lives,
          subtitle: <Trans i18nKey="PocketPage.StreakArtifactsLives" count={lives}/>,
        }}
      />
    </Container>
  );
}

export function StreakArtifacts({ data }: { data: 'loading' | DataProps }) {
  return data === 'loading' ? <Shimmers/> : <Data {...data} />;
}
