import { Trans, useTranslation } from 'react-i18next';
import {
  type ComponentType,
  type HTMLAttributes,
  type ReactNode,
  type SVGProps,
  useCallback,
} from 'react';
import cn from 'classnames';
import Markdown from 'react-markdown';

import { DefaultShimmer } from '../../../../../../_components/DefaultShimmer/DefaultShimmer';
import { TWAApplicationCard } from '../../../../../../../uilib/ApplicationCard/TWAApplicationCard';
import { StreaksTimer } from '../../../../../../_components/StreaksTimer/StreaksTimer';
import { Callout, Footnote } from '../../../../../../../uilib/Typography/Typography';
import { useOnTaskAppOpened as _useOnTaskAppOpened } from '../../../../hooks/useOnTaskAppOpened';
import { useNow } from '../../../../../../hooks/dates/useNow';
import type { FormattedTask } from '../../../../utils/formatTask';
import { Panel } from '../../../Panel/Panel';
import { PanelContent } from '../../../PanelContent/PanelContent';
import { useHiddenTasks } from '../../../../hooks/useHiddenTasks';

import { ReactComponent as LiveIcon } from '../../../../../../../svg/img/live.svg';
import { ReactComponent as CheckIcon } from '../../../../../../../svg/img/check.svg';
import s from './Task.module.scss';

export interface TaskData extends FormattedTask {
  isCompleted: boolean;
  // fixme: should use  from FormattedTask
  startsAt: Date;
}

const supportedRewards: Record<string, [i18nKey: string] | [
  i18nKey: string,
  Icon: ComponentType<SVGProps<SVGSVGElement>>,
  color: string
]> = {
  LIVE: ['StreaksPage.AdditionalTaskRewardLives', LiveIcon, '#FF7067'],
  POINT: ['StreaksPage.AdditionalTaskRewardPoints'],
  SPIN: ['StreaksPage.AdditionalTaskRewardSpins'],
};

function TaskShimmer() {
  return (
    <Panel title={<DefaultShimmer height={24}/>}>
      <PanelContent className={s.descriptionShimmer}>
        <DefaultShimmer width="90%" height={16}/>
        <DefaultShimmer width="70%" height={16}/>
        <DefaultShimmer width="30%" height={16}/>
      </PanelContent>
      <PanelContent className={s.rewardsShimmer}>
        <DefaultShimmer width={125} height={26} rounded/>
        <DefaultShimmer width={84} height={26} rounded/>
      </PanelContent>
      <PanelContent className={s.appShimmer}>
        <DefaultShimmer className={s.appShimmerIcon}/>
        <div className={s.appShimmerInfoWrap}>
          <DefaultShimmer width={90} height={22}/>
          <DefaultShimmer width={140} height={16}/>
          <DefaultShimmer width={60} height={18}/>
        </div>
      </PanelContent>
      <PanelContent className={s.timer}>
        <DefaultShimmer className={s.timerShimmer}/>
      </PanelContent>
    </Panel>
  );
}

function MarkdownParagraph(props: HTMLAttributes<HTMLParagraphElement>) {
  return <Callout weight="regular" {...props}/>;
}

function TaskPanel({
  app,
  title,
  description,
  rewards,
  id,
  endsAt,
  startsAt,
  isCompleted,
}: TaskData) {
  const { t } = useTranslation();
  const onTaskAppOpened = _useOnTaskAppOpened();
  const onOpened = useCallback(() => {
    onTaskAppOpened({ id, app, type: 'additional' });
  }, [app, id, onTaskAppOpened]);
  const now = useNow();
  const [, { hide: hideTask }] = useHiddenTasks();

  let titleNode: ReactNode;
  let descriptionNode: ReactNode;
  let timerNode: ReactNode;
  let onClose: (() => void) | undefined;

  // Leave only supported rewards.
  const filteredRewards = rewards.filter(({ type }) => type in supportedRewards);

  if (isCompleted) {
    const titleStr = title || t('StreaksPage.AdditionalTaskPanelTitle.Completed');
    const titleWords = titleStr.split(' ');
    const icon = <CheckIcon className={s.checkIcon} style={{ color: '#30D158' }}/>;

    titleNode = titleWords.length === 1 ? <>{titleStr}{icon}</> : (
      <>
        {titleWords.slice(0, titleWords.length - 1).join(' ')}{' '}
        <span style={{ whiteSpace: 'nowrap' }}>
          {titleWords[titleWords.length - 1]}
          {icon}
        </span>
      </>
    );
    descriptionNode = <Trans i18nKey="StreaksPage.AdditionalTaskPanelText.Completed"/>;
    onClose = () => {
      hideTask(id);
    };
  } else {
    titleNode = title || t('StreaksPage.AdditionalTaskPanelTitle');
    description && (descriptionNode = (
      <Markdown className={s.descriptionMarkdown} components={{ p: MarkdownParagraph }}>
        {description}
      </Markdown>
    ));
    timerNode = endsAt && (
      <PanelContent className={s.timer}>
        <StreaksTimer from={startsAt} to={endsAt} now={now}>
          <Trans i18nKey="StreaksPage.AdditionalTaskPanelTimer"/>
        </StreaksTimer>
      </PanelContent>
    );
  }

  const { icon } = app;

  return (
    <Panel
      className={cn(isCompleted && s.rootCompleted)}
      title={<span className={s.title}>{titleNode}</span>}
      onClose={onClose}
    >
      {descriptionNode && (
        <PanelContent className={s.description}>
          {descriptionNode}
        </PanelContent>
      )}
      {!!filteredRewards.length && (
        <PanelContent className={s.rewards}>
          {filteredRewards.map(({ type, value }, idx) => {
            const [titleKey, Icon, color] = supportedRewards[type];

            return (
              <Footnote className={s.reward} weight="medium" key={idx}>
                {Icon && <Icon style={{ color }}/>}
                <Trans i18nKey={titleKey} count={value}/>
              </Footnote>
            );
          })}
        </PanelContent>
      )}
      <TWAApplicationCard
        title={app.title || undefined}
        editorsChoice={app.editorsChoice || undefined}
        description={app.description || undefined}
        img={{ src: icon.url, name: icon.name || '' }}
        overrideOnClick={onOpened}
        onButtonClick={onOpened}
      />
      {timerNode}
    </Panel>
  );
}

export function Task({ data }: { data: 'loading' | TaskData }) {
  return data === 'loading' ? <TaskShimmer/> : <TaskPanel {...data}/>;
}
