import { useTranslation } from 'react-i18next';

import { usePocketCollections } from '../../../hooks/pocket/queries/usePocketCollections';
import { findArtifact } from '../../../pocket/artifacts';

export function useCollections() {
  const { t } = useTranslation();
  const { data } = usePocketCollections();

  return data
    ? data
      .map(collection => {
        const artifact = findArtifact(collection.type);
        return artifact ? {
          id: collection.collectionId,
          title: t(artifact.titleKey),
          description: t(artifact.shortDescriptionKey),
          price: collection.price,
          img: artifact.img.small,
          limit: artifact.limit,
          limitKey: artifact.limitKey,
          pocketType: artifact.pocketType,
        } : undefined;
      })
      .filter((item): item is Exclude<typeof item, undefined> => !!item)
    : undefined;
}