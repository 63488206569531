import { Trans, useTranslation } from 'react-i18next';

import { StatusPanel } from './StatusPanel/StatusPanel';
import { useCompletionPanelHidden } from '../hooks/useCompletionPanelHidden';

import { ReactComponent as CheckIcon } from '../../../../../../svg/img/check.svg';

export function TaskCompleted() {
  const { t } = useTranslation();
  const { hide } = useCompletionPanelHidden();

  return (
    <StatusPanel
      title={t('StreaksPage.StatusPanelTitle.TaskCompleted')}
      Icon={({ className }) => (
        <CheckIcon
          className={className}
          style={{ color: '#30D158' }}
        />
      )}
      onClose={hide}
    >
      <Trans i18nKey="StreaksPage.StatusPanelText.TaskCompleted"/>
    </StatusPanel>
  );
}
