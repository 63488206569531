import { publicUrl } from '../../../utils/url';
import { createSrcSet } from '../../../utils/createSrcSet';

export function createImage(
  name: string,
  { x, y, bgWidth, width, height }: {
    width?: number | string;
    height?: number | string;
    x?: number;
    y?: number;
    bgWidth?: number
  } = {},
) {
  const fullName = `envelope${name ? `-${name}` : ''}`;
  return {
    image: {
      src: publicUrl(`/red-packet/${fullName}/bg.png`),
      srcSet: createSrcSet(['', '@2x', '@3x'].map(suffix => {
        return publicUrl(`/red-packet/${fullName}/${fullName}${suffix}.png`);
      })),
      width,
      height,
      x,
      y,
    },
    bg: {
      src: publicUrl(`/red-packet/${fullName}/bg.png`),
      width: bgWidth || 400,
    },
  };
}