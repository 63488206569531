import { ReactNode } from 'react';

export function TextWithIcon({ children, icon }: {
  icon: ReactNode;
  children: string;
}) {
  const words = children.split(' ');

  return words.length ? (
    <>
      {words.slice(0, words.length - 1).join(' ')}{' '}
      <span style={{ whiteSpace: 'nowrap' }}>
        {words[words.length - 1]} {icon}
      </span>
    </>
  ) : <>{icon}</>;
}