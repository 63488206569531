import { useMemo, type FC, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { toMonthNameAndDate } from '../../../../utils/dates';
import { getStringGradient } from '../../../../utils/ui';
import { useLocaleContext } from '../../../providers/LocaleProvider/context';
import { DefaultShimmer } from '../../../_components/DefaultShimmer/DefaultShimmer';

import styles from './ReferralItem.module.scss';

export const ReferralItemsSkeleton: FC = () => {
  const item: ReferralItemSkeletonProps = {
    name: <DefaultShimmer width={70} height={20}/>,
    joinedAt: <DefaultShimmer width={150} height={18}/>,
  };
  const data: ReferralItemSkeletonProps[] = [
    item,
    item,
    item,
  ];
  return <>
    {data.map((item, index) => {
      return <ReferralItem key={index} {...item} />;
    })}
  </>;
};

export interface ReferralItemProps {
  name: string;
  joinedAt: Date;
}

export interface ReferralItemSkeletonProps {
  name: string | ReactNode;
  joinedAt: Date | ReactNode;
}

export const ReferralItem: FC<ReferralItemProps | ReferralItemSkeletonProps> = ({
  name,
  joinedAt,
}) => {
  const { locale } = useLocaleContext();
  const { t } = useTranslation();
  const { placeHolder, gradient } = useMemo(() => {
    if (typeof name !== 'string') {
      return {};
    }

    const placeholder = name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase();
    const gradient = getStringGradient(placeholder);
    return {
      placeHolder: placeholder,
      gradient: `linear-gradient(180deg, ${gradient[0]} 0%, ${gradient[1]} 100%)`,
    };
  }, [name]);

  return (
    <div className={styles.refContainer}>
      <div className={styles.refAvatarContainer}>
        {typeof name === 'string'
          ? <div className={styles.refAvatar} style={{ background: gradient }}>
            <span className={styles.refAvatarText}>{placeHolder}</span>
          </div>
          : <DefaultShimmer width={40} height={40} rounded/>
        }
      </div>
      <div className={styles.refContent}>
        <p className={styles.refName}>{name}</p>
        <p className={styles.refDate}>
          {joinedAt instanceof Date ? `${t('joined')} ${toMonthNameAndDate(joinedAt, locale)}` : joinedAt}
        </p>
      </div>
    </div>
  );
};
