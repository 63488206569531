import { useMemo } from 'react';

import { useIsUTCToday } from '../../../../../hooks/dates/useIsUTCToday';
import {
  useCloudStorageContext,
  useCloudStorageKey,
} from '../../../../../providers/CloudStorageProvider/context';
import { useNow } from '../../../../../hooks/dates/useNow';

export function useCompletionPanelHidden() {
  const { isLoading } = useCloudStorageContext();
  const [value, setValue] = useCloudStorageKey('dailyTaskCompletedPanelHiddenAt');
  const date = useMemo(() => new Date(value), [value]);
  const isToday = useIsUTCToday();
  const now = useNow();

  return {
    isHidden: isToday(date),
    isLoading,
    hide() {
      setValue(now.toISOString());
    },
  };
}