import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { StatusPanel } from '../StatusPanel/StatusPanel';
import { StreaksButton } from '../../../../../../_components/StreaksButton/StreaksButton';
import { useToolkit } from './useToolkit';
import { Body } from '../../../../../../../uilib/Typography/Typography';

import { ReactComponent as WarningIcon } from '../../../../../../../svg/img/warning.svg';
import { ReactComponent as SpinnerIcon } from '../../../../../../../svg/img/spinner24.svg';
import s from './StreakAlmostLost.module.scss';

export function StreakAlmostLost({ livesRequired, shouldPurchase }: {
  livesRequired: number;
  shouldPurchase: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, restoreStreak } = useToolkit();

  return (
    <StatusPanel
      title={t('StreaksPage.StatusPanelTitle.StreakAlmostLost')}
      Icon={({ className }) => (
        <WarningIcon
          className={className}
          style={{ color: '#FF9F0A' }}
        />
      )}
      button={
        <StreaksButton
          className={s.button}
          disabled={isLoading}
          onClick={() => {
            if (shouldPurchase) {
              navigate('/pocket');
            } else {
              restoreStreak(livesRequired);
            }
          }}
        >
          <Body weight="semi-bold">
            <Trans
              i18nKey={isLoading
                ? 'StreaksPage.StatusPanelButton.PurchasingLives'
                : shouldPurchase
                  ? 'StreaksPage.StatusPanelButton.StreakAlmostLostPurchaseLives'
                  : 'StreaksPage.StatusPanelButton.StreakAlmostLostExchangeLives'}
              count={livesRequired}
            />
            {isLoading && (
              <span className={s.spinner}>
                <SpinnerIcon className={s.spinnerIcon}/>
              </span>
            )}
          </Body>
        </StreaksButton>
      }
    >
      <Trans
        i18nKey={shouldPurchase
          ? 'StreaksPage.StatusPanelText.StreakAlmostLostPurchaseLives'
          : 'StreaksPage.StatusPanelText.StreakAlmostLostExchangeLives'}
        count={livesRequired}
      />
    </StatusPanel>
  );
}
