import useSWR from 'swr';

import { useApiClient } from '../../providers/ApiClientProvider/context';

/**
 * Requests streaks tasks information.
 */
export function useStreaksTasks() {
  const client = useApiClient();
  return useSWR('STREAKS_TASKS', client.getStreaksTasks.bind(client), {
    onError(err) {
      console.error('Failed to retrieve streaks tasks', err);
    },
  });
}