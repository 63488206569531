import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { StreaksPanel } from '../../../../_components/StreaksPanel/StreaksPanel';
import { Section } from '../Section/Section';
import { Body, Footnote, Heading } from '../../../../../uilib/Typography/Typography';
import { StreaksButton } from '../../../../_components/StreaksButton/StreaksButton';
import System from '../../../../../services/System';

import s from './InviteFriends.module.scss';

export function InviteFriends() {
  const navigate = useNavigate();

  return (
    <Section>
      <StreaksPanel className={s.panel}>
        <Heading className={s.title} weight="bold" type="h4">
          <Trans i18nKey="StreaksPage.InviteFriendsTitle"/>
        </Heading>
        <div className={s.textContainer}>
          <svg
            width="12"
            height="16"
            viewBox="0 0 12 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 15.1602C5.78125 15.1602 5.59896 15.0872 5.45312 14.9414C5.31185 14.8001 5.14095 14.5928 4.94043 14.3193L0.893555 8.91895C0.78418 8.77311 0.702148 8.63184 0.647461 8.49512C0.597331 8.35384 0.572266 8.21029 0.572266 8.06445C0.572266 7.91862 0.597331 7.77507 0.647461 7.63379C0.702148 7.49251 0.78418 7.35124 0.893555 7.20996L4.94043 1.80273C5.14095 1.53385 5.31185 1.32878 5.45312 1.1875C5.59896 1.04167 5.78125 0.96875 6 0.96875C6.21419 0.96875 6.39421 1.04167 6.54004 1.1875C6.68587 1.32878 6.85905 1.53385 7.05957 1.80273L11.1064 7.20996C11.2113 7.35124 11.291 7.49251 11.3457 7.63379C11.4004 7.77507 11.4277 7.91862 11.4277 8.06445C11.4277 8.21029 11.4004 8.35384 11.3457 8.49512C11.291 8.63184 11.2113 8.77311 11.1064 8.91895L7.05957 14.3193C6.85905 14.5928 6.68587 14.8001 6.54004 14.9414C6.39421 15.0872 6.21419 15.1602 6 15.1602Z"
              fill="#6A81C0"
            />
          </svg>
          <Footnote weight="medium">
            <Trans i18nKey="StreaksPage.InviteFriendsPointsBefore"/>
            {' '}
            <span className={s.points}>
              <Trans i18nKey="StreaksPage.InviteFriendsPointsCount"/>
            </span>
            {' '}
            <Trans i18nKey="StreaksPage.InviteFriendsPointsAfter"/>
          </Footnote>
        </div>
        <StreaksButton
          className={s.button}
          onClick={() => {
            System.delayAction(() => {
              navigate('/referral-programs');
            });
          }}
        >
          <Body weight="semi-bold">
            <Trans i18nKey="StreaksPage.InviteFriends"/>
          </Body>
        </StreaksButton>
      </StreaksPanel>
    </Section>
  );
}
