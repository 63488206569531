import { createContext, useCallback } from 'react';

import { createUseContext } from '../createUseContext';

export interface CloudStorageContextType {
  isLoading: boolean;
  values: Record<string, string>;
  /**
   * Sets the value in the Cloud Storage.
   * @param key
   * @param value
   */
  set(key: string, value: string): Promise<void>;
}

export type CloudStorageKey = keyof typeof cloudStorageKeysMapping;

/**
 * Object used to map external key to a real one.
 */
const cloudStorageKeysMapping = {
  dailyTaskCompletedPanelHiddenAt: 'streakStatusPanelHiddenAtTaskCompleted',
  dailyTaskOpenedAt: 'streaksDailyTaskOpenedAt',
  hiddenStreaksTasks: 'hiddenStreaksTasks',
  redPacketHiddenTasks: 'redPacketHiddenTasks',
  redPacketOverWidgetHidden: 'redPacketOverWidgetHidden',
};

export const CloudStorageContext = createContext<CloudStorageContextType | undefined>(undefined);

export const useCloudStorageContext = createUseContext(CloudStorageContext);

export function useCloudStorageKey(key: CloudStorageKey): [
  value: string,
  set: (value: string) => void
] {
  const mappedKey = cloudStorageKeysMapping[key];
  const { set, values } = useCloudStorageContext();

  return [
    values[mappedKey] || '',
    useCallback((value: string) => {
      void set(mappedKey, value);
    }, [mappedKey, set]),
  ];
}

export function useIsCloudStorageLoading(): boolean {
  return useCloudStorageContext().isLoading;
}