import { type MouseEventHandler, useCallback } from 'react';

import { Ripple, type RippleProps } from '../../../uilib/Ripple/Ripple';
import { useTWAPlatform } from '../../../hooks';

export interface SystemRippleProps extends RippleProps {
  /**
   * Should the click action be delayed.
   * @default If the current platform requires using ripples, the value will be `true`. False
   * otherwise.
   */
  delayClick?: boolean;
}

export function SystemRipple({ delayClick, onClick, ...rest }: SystemRippleProps) {
  const isRipplePlatform = ['android', 'tdesktop'].includes(useTWAPlatform());
  const wiredProps = {
    ...rest,
    onClick: useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
      if (onClick) {
        if (delayClick || (isRipplePlatform && delayClick === undefined)) {
          setTimeout(onClick, 400);
        } else {
          onClick(e);
        }
      }
    }, [delayClick, onClick, isRipplePlatform]),
  };

  return isRipplePlatform ? <Ripple {...wiredProps}/> : <div {...wiredProps}/>;
}