import { useMemo } from 'react';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
  type RouteObject,
} from 'react-router-dom';

import { Layout, type LayoutProps } from './Layout';
import IndexPage from '../pages/index/page';
import RecentPage from '../pages/recent/page';
import CategoryPage from '../pages/category/page';
import ApplicationPage from '../pages/application/page';
import ReferralProgramPage from '../pages/referralPrograms/page';
import { ReferralsPage } from '../pages/referralPrograms/referrals/page';
import { SettingsPage } from '../pages/settings/SettingsPage';
import { StreaksPage } from '../pages/streaks/StreaksPage';
import { PocketPage } from '../pages/pocket/PocketPage';
import { PocketCollectionPage } from '../pages/pocket-collection/PocketCollectionPage';
import { RedPacketPage } from './RedPacketPage/RedPacketPage';
import { useIsRedPacketEnabled } from '../hooks/flags/useIsRedPacketEnabled';
import { RedPacketOnboardingPage } from './RedPacketOnboardingPage/RedPacketOnboardingPage';
import { RedPacketInvitePage } from './RedPacketInvitePage/RedPacketInvitePage';
import { RedPacketOpenPacketPage } from './RedPacketOpenPacketPage/RedPacketOpenPacketPage';
import { RedPacketLeaguePage } from './RedPacketLeaguePage/RedPacketLeaguePage';
import { RedPacketTaskInfoPage } from './RedPacketTaskInfoPage/RedPacketTaskInfoPage';

import { TWA_ROOT_PATH } from '../../utils/constatns';

import 'swiper/css';
import '../../styles/globals.scss';

export function App({
  tonConnectReturnBaseUrl,
  tonConnectManifestUrl,
  appsRanking,
  appConfig,
  navigationConfig,
  mainPageBlockConfig,
  categories,
}: LayoutProps) {
  const isRedPacketEnabled = useIsRedPacketEnabled();
  const { flagsReady } = useFlagsStatus();

  const router = useMemo(() => {
    if (!flagsReady) {
      return;
    }
    const routes: RouteObject[] = [
      { path: '/', element: <IndexPage/>, index: true },
      { path: '/applications/:application', element: <ApplicationPage/> },
      { path: '/recent', element: <RecentPage/> },
      { path: '/settings', element: <SettingsPage/> },
      {
        path: '/referral-programs/:programId/referrals',
        element: <ReferralsPage/>,
        loader: (args) => {
          return Promise.resolve(args.params.programId ?? null);
        },
      },
      { path: '/referral-programs', element: <ReferralProgramPage/> },
      { path: '/streaks', element: <StreaksPage/> },
      { path: '/pocket', element: <PocketPage/> },
      { path: '/pocket/:collectionId', element: <PocketCollectionPage/> },
    ];
    if (isRedPacketEnabled) {
      routes.push(
        { path: '/red-packet', element: <RedPacketPage/> },
        { path: '/red-packet/onboarding', element: <RedPacketOnboardingPage/> },
        { path: '/red-packet/invite', element: <RedPacketInvitePage/> },
        { path: '/red-packet/league', element: <RedPacketLeaguePage/> },
        { path: '/red-packet/open', element: <RedPacketOpenPacketPage/> },
        { path: '/red-packet/task-info', element: <RedPacketTaskInfoPage/> },
      );
    }
    routes.push(
      { path: '/:category', element: <CategoryPage/> },
      { path: '*', element: <Navigate to="/"/> },
    );

    return createBrowserRouter([{
      id: 'root',
      element: (
        <Layout
          tonConnectReturnBaseUrl={tonConnectReturnBaseUrl}
          tonConnectManifestUrl={tonConnectManifestUrl}
          appsRanking={appsRanking}
          appConfig={appConfig}
          navigationConfig={navigationConfig}
          mainPageBlockConfig={mainPageBlockConfig}
          categories={categories}
        />
      ),
      children: routes,
    }], { basename: TWA_ROOT_PATH });
  }, [
    tonConnectReturnBaseUrl,
    tonConnectManifestUrl,
    appsRanking,
    appConfig,
    navigationConfig,
    mainPageBlockConfig,
    categories,
    isRedPacketEnabled,
    flagsReady,
  ]);

  return router ? <RouterProvider router={router}/> : null;
}
