import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Heading } from '../../../../uilib/Typography/Typography';
import { Artifact, ArtifactShimmer } from './Artifact/Artifact';
import { useTonConnect } from '../../../providers/TonConnectProvider/context';
import { useActiveOrder } from './useActiveOrder';
import { useHapticFeedback } from '../../../hooks/useHapticFeedback';
import { useShowAlert } from '../../../hooks/useShowAlert';
import { useSelectedItems } from './useSelectedItems';
import { useCollections } from './useCollections';
import { useUserPocket } from '../../../hooks/pocket/queries/useUserPocket';
import { useMainButton } from './useMainButton';
import { usePurchasePocketItems } from '../../../hooks/pocket/mutations/usePurchasePocketItems';
import { usePendingOrder } from './usePendingOrder';
import { findArtifact } from '../../../pocket/artifacts';

import s from './Artifacts.module.scss';

function useAlerts() {
  const { t } = useTranslation();
  const hapticFeedback = useHapticFeedback();
  const showAlert = useShowAlert();

  const showErrorAlert = useCallback((text: string) => {
    hapticFeedback.notificationOccurred('error');
    showAlert(text);
  }, [hapticFeedback, showAlert]);

  return {
    showInsufficientFundsAlert: useCallback(() => {
      showErrorAlert(t('PocketPage.AlertInsufficientFunds'));
    }, [showErrorAlert, t]),
    showLimitReachedAlert: useCallback((translationKey: string) => {
      showErrorAlert(t(translationKey));
    }, [showErrorAlert, t]),
    showWalletDisconnectedAlert: useCallback(() => {
      showErrorAlert(t('PocketPage.AlertWalletShouldBeConnected'));
    }, [showErrorAlert, t]),
    showPurchaseCompletedAlert: useCallback(() => {
      hapticFeedback.notificationOccurred('success');
      showAlert(t('PocketPage.PurchaseCompleted'));
    }, [t, hapticFeedback, showAlert]),
    showOrderFailedAlert: useCallback((reason: 'unknown' | 'limit-reached') => {
      showErrorAlert(
        t(reason === 'unknown'
          ? 'PocketPage.AlertOrderFailedUnknown'
          // FIXME: Temporarily leaving this key here for release. We should define a more
          //  generic message probably.
          : 'PocketPage.AlertLotteryTicketsLimitReached'),
      );
    }, [showErrorAlert, t]),
    showTimeIsUpAlert: useCallback(() => {
      showErrorAlert(t('PocketPage.AlertPurchaseDisallowed'));
    }, [showErrorAlert, t]),
  };
}

export function Artifacts() {
  const { t } = useTranslation();
  const { wallet } = useTonConnect();
  const navigate = useNavigate();
  const { data: userPocket, mutate: mutateUserPocket } = useUserPocket();

  // Artifacts to display.
  const collections = useCollections();
  const usdtArtifact = findArtifact('USDT');

  // Controls used to manipulate selected artifacts.
  const {
    totalPrice,
    getCountSelected,
    changeCountSelected,
    getSelectedItems,
    resetSelected,
  } = useSelectedItems(
    useMemo(() => collections || [], [collections]),
    useMemo(() => userPocket || {}, [userPocket]),
  );

  // Handlers showing alerts.
  const {
    showWalletDisconnectedAlert,
    showLimitReachedAlert,
    showInsufficientFundsAlert,
    showPurchaseCompletedAlert,
    showOrderFailedAlert,
    showTimeIsUpAlert,
  } = useAlerts();

  const [hasActiveOrder, { set: setHasActiveOrder }] = usePendingOrder();
  useActiveOrder({
    hasPendingOrder: !!hasActiveOrder.data,
    onPending() {
      setHasActiveOrder(true);
    },
    onCompleted() {
      setHasActiveOrder(false);

      // Revalidate user pocket information and reset selected items.
      void mutateUserPocket();
      resetSelected();

      // Making notification asynchronous as long as calling it right away will prevent
      // the Main Button from hiding. resetSelected will drop selected items, but then
      // the alert will be shown preventing the code from getting to the Main Button state
      // update. As a result, the Main Button will be updated only when the alert was hidden.
      setTimeout(showPurchaseCompletedAlert, 100);
    },
    onFailed(reason: 'unknown' | 'limit-reached') {
      setHasActiveOrder(false);

      showOrderFailedAlert(reason);

      // If a limit was reached, it seems like we have an actual state of the user pocket,
      // so we have to revalidate it.
      if (reason === 'limit-reached') {
        void mutateUserPocket();
      }
    },
    onMissing() {
      setHasActiveOrder(false);
    },
  });

  const {
    trigger: purchasePocketItems,
    isMutating: isPurchasingPocketItems,
  } = usePurchasePocketItems({
    onSuccess() {
      // After the purchase was completed, we expect a new active order to appear.
      setHasActiveOrder(true);
    },
  });

  useMainButton({
    isLoading: isPurchasingPocketItems || !!hasActiveOrder.data,
    totalPrice,
    purchase: useCallback(() => {
      wallet && purchasePocketItems({
        wallet: wallet.address,
        items: getSelectedItems(),
      });
    }, [wallet, purchasePocketItems, getSelectedItems]),
  });

  return (
    <div className={s.root}>
      {!!(collections && userPocket && userPocket.usdt && usdtArtifact) && (
        <div className={s.section}>
          <Heading weight="bold" type="h4">
            <Trans i18nKey="PocketPage.TokensToClaimTitle"/>
          </Heading>
          <div className={s.items}>
            <Artifact
              img={usdtArtifact.img.small}
              id="USDT"
              price={0}
              title={t(usdtArtifact.titleKey)}
              description={t(usdtArtifact.shortDescriptionKey, { count: userPocket.usdt })}
              countSelected={0}
              onCountChanged={() => {
              }}
              _pickerText={t('PocketPage.ToClaimButton')}
              _onPickerClick={() => {
                navigate('/pocket/USDT');
              }}
              canAdd
            />
          </div>
        </div>
      )}

      <div className={s.section}>
        <Heading weight="bold" type="h4">
          <Trans i18nKey="PocketPage.ArtifactsTitle"/>
        </Heading>
        <div className={s.items}>
          {collections && userPocket ? (
            collections.map((collection) => {
              const countSelected = getCountSelected(collection.id);
              const countAcquired = (
                collection.pocketType
                  ? userPocket[collection.pocketType]
                  : undefined
              ) || 0;
              const hasEnoughFundsToAdd = totalPrice + collection.price <= userPocket.points;
              const purchaseLimit = collection.limit
                ? collection.limit - countAcquired
                : Number.POSITIVE_INFINITY;
              const isLimitReached = countSelected >= purchaseLimit;
              const showLimitAlert = () => {
                collection.limitKey && showLimitReachedAlert(collection.limitKey);
              };
              const isTicket = collection.pocketType === 'nftTickets';

              return (
                <Artifact
                  {...collection}
                  key={collection.id}
                  countSelected={countSelected}
                  onCountChanged={changeCountSelected}
                  canAdd={hasEnoughFundsToAdd && !isLimitReached}
                  onLockedClick={
                    isTicket
                      ? showTimeIsUpAlert
                      : !wallet
                        ? showWalletDisconnectedAlert
                        : !countSelected
                          ? isLimitReached
                            ? showLimitAlert
                            : !hasEnoughFundsToAdd
                              ? showInsufficientFundsAlert
                              : undefined
                          : undefined

                  }
                  onAddLockedClick={
                    isLimitReached
                      ? showLimitAlert
                      : hasEnoughFundsToAdd
                        ? undefined
                        : showInsufficientFundsAlert
                  }
                  _pickerLockedText={isTicket
                    ? <Trans i18nKey="PocketPage.PurchaseButtonTimeIsUp"/>
                    : undefined}
                />
              );
            })
          ) : (
            <>
              <ArtifactShimmer/>
              <ArtifactShimmer/>
              <ArtifactShimmer/>
              <ArtifactShimmer/>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
