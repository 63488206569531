import React, { PropsWithChildren, useEffect, useState } from 'react';
import cn from 'classnames';

import { RecentlyLaunched } from '../../app/_components/RecentlyLaunched/RecentlyLaunched';
import { setRecentlyLaunchedContainerHeight } from '../../app/_components/RecentlyLaunched/utils';
import { useConfigContext } from '../../dataSource/ConfigContext';
import { getItemFromStorage } from '../../hooks/hooks';
import { useIsRedPacketEnabled } from '../../app/hooks/flags/useIsRedPacketEnabled';
import { RedPacketWidget } from '../../app/_components/RedPacketWidget/RedPacketWidget';
import { Tabs } from './Tabs';
import { StreaksBanner } from '../../app/_components/StreaksBanner/StreaksBanner';
import { useIsRedPacketOver } from '../../app/hooks/flags/useIsRedPacketOver';

import styles from './styles.module.scss';

export const TWATabs: React.FC<PropsWithChildren> = () => {
  const [
    isRecentlyLaunchedExists,
    setRecentlyLaunchedExists,
  ] = useState(window.isRecentlyLaunchedEnabled);
  const redPacketEnabled = useIsRedPacketEnabled();
  const redPacketIsOver = useIsRedPacketOver();

  const appConfig = useConfigContext();

  useEffect(() => {
    const platform = window.Telegram.WebApp.platform;
    const isRecentlyLaunchedEnabledOnPlatform = appConfig?.config?.recently_launched_enabled?.data
      .some(({ attributes }) => {
        return attributes.name === platform;
      });

    getItemFromStorage('recentlyLaunched')
      .then((res) => {
        if (res) {
          const showRecentlyLaunched = Boolean(isRecentlyLaunchedEnabledOnPlatform) &&
            (Boolean(window.isRecentlyLaunchedExists) || Boolean(res));

          if (showRecentlyLaunched) {
            window.isRecentlyLaunchedEnabled = true;
            setRecentlyLaunchedContainerHeight();
          }

          setRecentlyLaunchedExists(showRecentlyLaunched);
        }
      })
      .catch((e) => {
        if (process.env.NODE_ENV === 'development') {
          // console.log(e)
        }
      });
  }, [appConfig]);
  const displayRecentlyLaunched = window.isRecentlyLaunchedEnabled || isRecentlyLaunchedExists;

  return (
    <>
      <div className={styles.placeholder}/>
      {redPacketEnabled && !redPacketIsOver && (
        <section className={cn(styles.redPacket, displayRecentlyLaunched && styles.redPacketWithRecent)}>
          <RedPacketWidget/>
        </section>
      )}
      {displayRecentlyLaunched && <RecentlyLaunched/>}
      <Tabs/>
      <div style={{ marginTop: displayRecentlyLaunched ? 14 : 0 }}>
        <StreaksBanner/>
      </div>
    </>
  );
};
