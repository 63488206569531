import { useTranslation } from 'react-i18next';

import { RedPacketTask, type RedPacketTaskProps } from '../RedPacketTask/RedPacketTask';

interface Props extends Omit<
  RedPacketTaskProps,
  | 'completed'
  | 'type'
  | 'title'
  | 'text'
  | 'openTitle'
  | 'openText'
  | 'openType'
  | 'openButtonText'
  | 'highlight'
> {
}

export function RedPacketTaskCompleteAll(props: Props) {
  const { t } = useTranslation();
  return (
    <RedPacketTask
      {...props}
      completed={true}
      boxToSendSharing={{ shareMessage: { id: '1' }, sharedAt: new Date(), lootBoxId: 1 }}
      type="completable"
      title={t('RedPacketTaskCompleteAll.Title')}
      text={t('RedPacketTaskCompleteAll.Text')}
      openTitle={t('RedPacketTaskCompleteAll.Title')}
      openText={t('RedPacketTaskCompleteAll.Text')}
      openType="complete-all"
      openButtonText={t('RedPacketTaskCompleteAll.ButtonText')}
      highlight={!props.boxToOpen || !props.boxToOpen.isOpened}
    />
  );
}