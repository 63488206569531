import { type AnchorHTMLAttributes, useEffect, useMemo } from 'react';
import Markdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { State } from '../State/State';
import { createImage } from '../createImage';
import { Callout } from '../../../../uilib/Typography/Typography';
import { useLocaleContext } from '../../../providers/LocaleProvider/context';
import type { RedPacketTaskPacketType } from '../../../helpers/types';
import { openTelegramLink } from '../../../../utils/common';
import { useIsRedPacketOver } from '../../../hooks/flags/useIsRedPacketOver';
import {
  useRedPacketUnopenedPacketsCount,
} from '../../../hooks/red-packet/useRedPacketUnopenedPacketsCount';

import s from './PrizeState.module.scss';

function MarkdownAnchor({ href, children }: AnchorHTMLAttributes<HTMLAnchorElement>) {
  const navigate = useNavigate();
  return (
    <a
      className={s.anchor}
      onClick={e => {
        if (href) {
          e.preventDefault();
          if (href.startsWith('https://t.me')) {
            openTelegramLink(href);
          } else {
            navigate(href, { replace: true });
          }
        }
      }}
      href={href}
    >
      {children}
    </a>
  );
}

export function PrizeState({ type, value, shared }: {
  shared: boolean;
  type: RedPacketTaskPacketType;
  value: number;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isRedPacketOver = useIsRedPacketOver();
  const unopenedPacketsCount = useRedPacketUnopenedPacketsCount();
  const hasUnopenedPackets = !!(typeof unopenedPacketsCount === 'number' && unopenedPacketsCount);
  const { locale } = useLocaleContext();
  const screens = useMemo<Partial<Record<RedPacketTaskPacketType, {
    image: {
      src: string;
      srcSet: string;
      width?: number | string;
      height?: number | string;
      x?: number;
      y?: number
    };
    bg: {
      src: string;
      width: number;
    };
    nameKey: string;
    subtitleKey: string;
  }>>>(() => ({
    USDT: {
      ...createImage('stars', { height: '100%' }),
      nameKey: 'RedPacketOpenPacketPage.PrizeNameUsdt',
      subtitleKey: 'RedPacketOpenPacketPage.PrizeTextUsdt',
    },
    TELEGRAM_GIFT: {
      ...createImage('gift', { height: '100%' }),
      nameKey: 'RedPacketOpenPacketPage.PrizeNameTelegramGift',
      subtitleKey: 'RedPacketOpenPacketPage.PrizeTextTelegramGift',
    },
    POINT: {
      ...createImage('points', { height: '100%' }),
      nameKey: 'RedPacketOpenPacketPage.PrizeNameStreakPoints',
      subtitleKey: 'RedPacketOpenPacketPage.PrizeTextStreakPoints',
    },
    LIVE: {
      ...createImage('lives', { height: '100%' }),
      nameKey: 'RedPacketOpenPacketPage.PrizeNameStreakLive',
      subtitleKey: 'RedPacketOpenPacketPage.PrizeTextStreakLive',
    },
  }), []);
  const screen = screens[type] || {
    ...createImage('stars', { height: '130%' }),
    nameKey: 'RedPacketOpenPacketPage.PrizeNameUnknown',
    subtitleKey: 'RedPacketOpenPacketPage.PrizeTextUnknown',
  };
  const {
    MainButton: mb,
    SecondaryButton: sb,
    themeParams: tp,
  } = window.Telegram.WebApp;

  useEffect(() => {
    const onSbClick = () => {
      navigate(-1);

      if (isRedPacketOver && !hasUnopenedPackets) {
        navigate(shared ? '/' : '/streaks', { replace: true });
      }
    };
    sb.onClick(onSbClick);
    const onMbClick = () => {
      navigate('/pocket', { replace: true });
    };

    const timeoutId = setTimeout(() => {
      sb.setParams({
        is_active: true,
        is_visible: true,
        text: t(isRedPacketOver
          ? hasUnopenedPackets
            ? 'RedPacketOpenPacketPage.OpenMore'
            : shared
              ? 'RedPacketOpenPacketPage.GoToMain'
              : 'RedPacketOpenPacketPage.GoToStreaks'
          : shared
            ? 'RedPacketOpenPacketPage.GetMorePackets'
            : 'RedPacketOpenPacketPage.BackToTasks'),
        color: '#372A2E',
        text_color: '#ffffff',
        position: locale === 'ru' ? 'top' : 'left',
      });

      if (['USDT', 'POINT', 'LIVE'].includes(type)) {
        mb.onClick(onMbClick);
        mb.setParams({
          is_active: true,
          is_visible: true,
          text: t('RedPacketOpenPacketPage.ClaimInPocket'),
          color: '#571D1B',
          text_color: '#ffffff',
        });
      }
    }, 2000);

    return () => {
      sb.offClick(onSbClick);
      mb.offClick(onMbClick);
      clearTimeout(timeoutId);
    };
  }, [t, navigate, type, mb, sb, locale, shared, isRedPacketOver, hasUnopenedPackets]);

  useEffect(() => {
    return () => {
      mb.setParams({
        is_visible: false,
        color: tp.button_color,
        text_color: tp.button_text_color,
      });
      sb.setParams({
        is_visible: false,
        color: tp.button_color,
        text_color: tp.button_text_color,
      });
    };
  }, [mb, sb, tp]);

  return (
    <State
      title={t('RedPacketOpenPacketPage.PrizeTitle', { prize: t(screen.nameKey, { count: value }) })}
      image={screen.image}
      bg={screen.bg}
    >
      <Callout className={s.subtitle} weight="regular">
        <Markdown components={{ a: MarkdownAnchor, p: 'span' }}>
          {t(screen.subtitleKey)}
        </Markdown>
      </Callout>
    </State>
  );
}