import type { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  colorFrom: string;
  colorTo: string;
}

export function StarIcon({ colorFrom, colorTo, ...rest }: Props) {
  const gradientId = `paint0_linear_${colorFrom}_${colorTo}`;

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.96966 7.6351L11.4437 2.80668C11.7291 2.24965 12.4191 2.02586 12.985 2.30684C13.2071 2.41714 13.3868 2.59554 13.497 2.81513L15.8334 7.4716C16.0243 7.85198 16.3974 8.1117 16.8254 8.16201L21.7181 8.73722C22.3823 8.8153 22.8564 9.40869 22.7771 10.0626C22.7444 10.3319 22.6195 10.5822 22.4229 10.7722L18.5557 14.5089C18.3995 14.6598 18.3239 14.8737 18.3511 15.0875L18.9938 20.1322C19.0858 20.8544 18.5656 21.5133 17.832 21.6039C17.554 21.6382 17.2721 21.5858 17.0259 21.454L12.9401 19.2667C12.6445 19.1085 12.2887 19.1039 11.989 19.2546L7.75585 21.3825C7.16009 21.682 6.43051 21.4493 6.12631 20.8628C6.01248 20.6434 5.97051 20.3946 6.00616 20.1507L6.34449 17.8362C6.50997 16.7041 7.22466 15.7222 8.2603 15.2039L12.9619 12.851C13.0875 12.7882 13.1375 12.6371 13.0737 12.5135C13.0242 12.4178 12.9184 12.3636 12.8104 12.3787L7.06031 13.1858C6.1813 13.3092 5.28987 13.066 4.6007 12.5149L2.68548 10.9833C2.13894 10.5462 2.05579 9.75575 2.49976 9.21771C2.70622 8.9675 3.00256 8.80508 3.32736 8.76412L8.23741 8.14488C8.55232 8.10516 8.82668 7.91416 8.96966 7.6351Z"
        fill={`url(#${gradientId}`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="5.21436"
          y1="12"
          x2="20.6429"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colorFrom}/>
          <stop offset="1" stopColor={colorTo}/>
        </linearGradient>
      </defs>
    </svg>
  );
}