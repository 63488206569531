import useSWR from 'swr';

import { useApiClient } from '../../providers/ApiClientProvider/context';

/**
 * Requests streaks leagues information.
 */
export function useStreaksLeagues() {
  const client = useApiClient();
  return useSWR('STREAKS_LEAGUES', client.getStreaksLeagues.bind(client), {
    onError(err) {
      console.error('Error fetching streaks leagues', err);
    },
  });
}