import { useTranslation } from 'react-i18next';

import { RedPacketTask, type RedPacketTaskProps } from '../RedPacketTask/RedPacketTask';

interface Props extends Pick<RedPacketTaskProps, 'id' | 'completed' | 'onHide'> {
  leagueId: number;
  days: number;
  titleKey: string;
  icon: { src: string; srcSet: string };
}

export function RedPacketTaskReachLeague({ titleKey, icon, days, leagueId, ...rest }: Props) {
  const { t } = useTranslation();
  return (
    <RedPacketTask
      {...rest}
      type="completable"
      title={t(titleKey)}
      text={t('RedPacketReachLeagueTask.Text', { count: days })}
      infoPath={`/red-packet/league?id=${leagueId}&days=${days}`}
      headerIcon={icon}
    />
  );
}