import { useMemo } from 'react';

import { useCloudStorageKey } from '../../../providers/CloudStorageProvider/context';

/**
 * @returns Value and utilities related to streaks hidden tasks.
 */
export function useHiddenTasks() {
  const [value, set] = useCloudStorageKey('hiddenStreaksTasks');
  const tasks = useMemo<number[]>(() => {
    try {
      return value ? JSON.parse(value) : [];
    } catch (e) {
      return [];
    }
  }, [value]);

  return [tasks, {
    hide(id: number) {
      set(JSON.stringify([...tasks, id]));
    },
  }] as const;
}