import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';
import useSWRMutation from 'swr/mutation';

import { Body, Caption } from '../../../uilib/Typography/Typography';
import { RedPacketPanel } from '../RedPacketPanel/RedPacketPanel';
import {
  RedPacketTaskText,
  RedPacketTaskTextShimmer,
} from '../RedPacketTaskText/RedPacketTaskText';
import {
  RedPacketTaskHeader,
  type RedPacketTaskHeaderIcon,
  RedPacketTaskHeaderShimmer,
} from '../RedPacketTaskHeader/RedPacketTaskHeader';
import { SystemRipple } from '../SystemRipple/SystemRipple';
import { DefaultShimmer } from '../DefaultShimmer/DefaultShimmer';
import { useApiClient } from '../../providers/ApiClientProvider/context';
import { useRedPacketState } from '../../hooks/red-packet/useRedPacketState';
import type { RedPacketTaskPacketType } from '../../helpers/types';
import { ApiError } from '../../../shared/http/ApiError';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
import { useShowAlert } from '../../hooks/useShowAlert';
import { RedPacketButton } from '../RedPacketButton/RedPacketButton';
import { useTonConnect } from '../../providers/TonConnectProvider/context';

import { ReactComponent as CloseIcon } from '../../../svg/img/close-circle.svg';
import { ReactComponent as CheckIcon } from '../../../svg/img/check.svg';
import { ReactComponent as InfoIcon } from '../../../svg/img/info-24.svg';
import s from './RedPacketTask.module.scss';

export interface RedPacketTaskProps extends PropsWithChildren {
  id: number;
  title: string;
  type: 'completable' | 'purchasable';
  completed?: Maybe<boolean>;
  boxToSendSharing?: Maybe<{
    lootBoxId: number;
    sharedAt?: Maybe<Date>;
    shareMessage: {
      id: string;
    };
  }>;
  boxToOpen?: Maybe<{
    isOpened: boolean;
    type: RedPacketTaskPacketType;
    value: number;
  }>;
  text: ReactNode;
  notCompletedErrorMessage?: string;
  infoPath?: Maybe<string>;
  headerIcon?: RedPacketTaskHeaderIcon;
  onHide(): void;
  openTitle?: string;
  openText?: string;
  openType?: string;
  openButtonText?: string;
  highlight?: boolean;
}

export function RedPacketTask({
  children,
  title,
  headerIcon,
  text,
  type,
  infoPath,
  id,
  boxToOpen,
  boxToSendSharing,
  completed,
  onHide,
  notCompletedErrorMessage,
  openButtonText,
  openText,
  openTitle,
  openType,
  highlight,
}: RedPacketTaskProps) {
  const haptic = useHapticFeedback();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useApiClient();
  const { wallet } = useTonConnect();
  const { mutate: mutateState } = useRedPacketState();
  const mutateStateById = (
    id: number,
    task: {
      completed: boolean;
      boxToSendSharing?: Maybe<{
        lootBoxId: number;
        sharedAt?: Maybe<Date>;
        shareMessage: {
          id: string;
        };
      }>;
      boxToOpen?: Maybe<{
        isOpened: boolean;
        type: RedPacketTaskPacketType;
        value: number;
      }>;
    },
  ) => mutateState(s => {
    const state = s || [];
    const index = state.findIndex(item => item.id === id);
    if (index >= 0) {
      state[index] = { ...state[index], ...task };
    } else {
      state.push({ id, ...task });
    }
    return [...state];
  });

  const showAlert = useShowAlert();

  const {
    trigger: completeTask,
    isMutating: isCompleting,
  } = useSWRMutation(`complete-red-packet-task-${id}`, () => client.completeRedPacketTask(id), {
    onSuccess(data) {
      haptic.notificationOccurred('success');
      mutateStateById(id, data);
    },
    onError(err) {
      if (err instanceof ApiError) {
        if (err.hasErrorCode('ERROR_TASK_ALREADY_COMPLETED')) {
          mutateState();
          return;
        }
        if (err.hasErrorCode('ERROR_TASK_NOT_COMPLETED')) {
          showAlert(notCompletedErrorMessage || t('RedPacketTask.AlertTaskNotCompleted'));
        }
      }
      haptic.notificationOccurred('error');
    },
  });

  const {
    trigger: sendPacket,
    isMutating: isSendingPacket,
  } = useSWRMutation(`send-red-packet-task-packet-${id}`, () => client.sendRedPacketTaskPacket(id), {
    onSuccess(data) {
      const id = data.boxToSendSharing?.shareMessage.id;
      id && (window.Telegram.WebApp as any).shareMessage(id, (sent: boolean) => {
        if (sent) {
          confirmPacketSent();
        }
      });
    },
    onError() {
      haptic.notificationOccurred('error');
    },
  });

  const {
    trigger: confirmPacketSent,
    isMutating: isConfirmingPacketSent,
  } = useSWRMutation(`notify-red-packet-message-sent-${id}`, () => client.confirmRedPacketTaskPacketSend(id), {
    onSuccess(data) {
      mutateStateById(id, data);
    },
    onError() {
      haptic.notificationOccurred('error');
    },
  });

  const isWalletConnected = !!wallet;
  const isPacketOpened = !!boxToOpen?.isOpened;
  const isPacketShared = !!boxToSendSharing?.sharedAt;
  const isCompletedNotShared = !!completed && !isPacketShared;
  const isSharingSupported =
    window.Telegram.WebApp.isVersionAtLeast('8.0')
    && window.Telegram.WebApp.platform !== 'weba';
  const onButtonClick = completed
    ? isPacketShared
      ? isPacketOpened
        ? undefined
        : () => {
          navigate(`/red-packet/open?task_id=${id}&task_type=${openType || ''}`);
        }
      : () => sendPacket()
    : isWalletConnected
      ? () => completeTask()
      : undefined;
  const showNotSupported = isCompletedNotShared && !isSharingSupported;
  const showWalletNotConnected = !completed && !isWalletConnected;

  const isButtonLoading = isCompleting || isSendingPacket || isConfirmingPacketSent;
  const isButtonShown = showWalletNotConnected || showNotSupported || !!onButtonClick;
  const isButtonDisabled = showWalletNotConnected || showNotSupported || isButtonLoading;

  return (
    <RedPacketPanel
      className={cn(s.root, !isButtonShown && s.rootNoButton, highlight && s.rootHighlight)}
    >
      {(infoPath || isPacketOpened) && (
        <SystemRipple
          className={s.info}
          onClick={() => {
            if (isPacketOpened) {
              onHide();
            } else if (infoPath) {
              navigate(infoPath);
            }
          }}
        >
          {isPacketOpened
            ? <CloseIcon className={s.headerIcon}/>
            : <InfoIcon className={s.headerIcon}/>}
        </SystemRipple>
      )}
      <RedPacketTaskHeader
        className={cn((infoPath || isPacketOpened) && s.headerWithIcon)}
        icon={
          isPacketShared && !isPacketOpened
            ? undefined
            : completed
              ? ({ className }) => <CheckIcon className={cn(s.completedIcon, className)}/>
              : headerIcon}
      >
        {isPacketShared && !isPacketOpened
          ? openTitle || t('RedPacketTask.PacketSentTitle')
          : title}
      </RedPacketTaskHeader>
      <RedPacketTaskText>
        {completed
          ? isPacketShared
            ? isPacketOpened
              ? t('RedPacketTask.ClaimedAndCompleted')
              : openText || t('RedPacketTask.OpenPocketText')
            : t('RedPacketTask.CompletedText')
          : text}
      </RedPacketTaskText>
      {!completed && children}
      {isButtonShown && (
        <div className={s.footer}>
          <RedPacketButton
            color={
              isCompletedNotShared
                ? isSharingSupported
                  ? 'yellow'
                  : undefined
                : isPacketShared && !isPacketOpened
                  ? 'red'
                  : undefined}
            onClick={onButtonClick}
            loading={isButtonLoading}
            disabled={isButtonDisabled}
            muteOnDisabled={
              (!completed && !isWalletConnected) || (isCompletedNotShared && !isSharingSupported)
            }
            shining={!isButtonDisabled && !!completed}
            fullWidth
          >
            {showNotSupported ? (
              <>
                <Body className={s.buttonText} weight="semi-bold">
                  {t('RedPacketTask.SharingNotSupportedTitle')}
                </Body>
                <Caption className={s.buttonText} weight="regular" level={2}>
                  {t('RedPacketTask.SharingNotSupportedSubtitle')}
                </Caption>
              </>
            ) : showWalletNotConnected ? (
              <>
                <Body className={s.buttonText} weight="semi-bold">
                  {t('RedPacketTask.UnableToCompleteTitle')}
                </Body>
                <Caption className={s.buttonText} weight="regular" level={2}>
                  {t('RedPacketTask.UnableToCompleteSubtitle')}
                </Caption>
              </>
            ) : (
              <Body className={s.buttonText} weight="semi-bold">
                {showWalletNotConnected
                  ? t('RedPacketTask.ConnectWalletToStart')
                  : completed
                    ? isPacketShared
                      ? isPacketOpened
                        ? undefined
                        : openButtonText || t('RedPacketTask.OpenPacket')
                      : t('RedPacketTask.SendPacket')
                    : t(isCompleting
                      ? 'RedPacketTask.CheckingProgress'
                      : type === 'completable'
                        ? 'RedPacketTask.CheckProgress'
                        : 'RedPacketTask.Purchase')}
              </Body>
            )}
          </RedPacketButton>
        </div>
      )}
    </RedPacketPanel>
  );
}

export const RedPacketTaskShimmer = () => (
  <RedPacketPanel className={s.root}>
    <RedPacketTaskHeaderShimmer/>
    <RedPacketTaskTextShimmer/>
    <div className={s.footer}>
      <DefaultShimmer height={50} style={{ borderRadius: 10 }}/>
    </div>
  </RedPacketPanel>
);