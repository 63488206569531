import { type PropsWithChildren, useCallback, useMemo } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { CloudStorageContext, type CloudStorageContextType } from './context';

/**
 * Provides utilities to work with the Cloud Storage.
 */
export function CloudStorageProvider(props: PropsWithChildren) {
  const { CloudStorage: cs } = Telegram.WebApp;

  const {
    data: storage,
    isLoading: isStorageLoading,
    mutate: mutateStorage,
  } = useSWR('telegram cloud storage', () => {
    return new Promise<Record<string, string>>((res, rej) => {
      cs.getKeys((err, keys) => {
        if (err) {
          return rej(new Error(err));
        }
        cs.getItems(keys as string[], (err, values) => {
          if (err) {
            return rej(new Error(err));
          }
          res(values as Record<string, string>);
        });
      });
    });
  }, {
    fallbackData: {},
    refreshInterval: 15000,
    onError(err) {
      console.error('Error fetching cloud storage keys', err);
    },
  });

  const setMutation = useSWRMutation(
    'set telegram cloud storage key',
    (_, { arg }: { arg: { key: string; value: string } }) => {
      return new Promise<void>((res, rej) => {
        cs.setItem(arg.key, arg.value, err => {
          if (err) {
            return rej(new Error(err));
          }
          res();
        });
      });
    },
    {
      onError(err) {
        console.error('Error setting CS key', err);
      },
    },
  );

  const set = useCallback(async (key: string, value: string) => {
    // Initially mutate the storage, so we would have the optimistic value.
    await mutateStorage(prev => ({ ...prev, [key]: value }), {
      revalidate: false,
    });

    try {
      // Call the Cloud Storage set.
      await setMutation.trigger({ key, value });
      // Revalidate the storage state.
      await mutateStorage();
    } catch (e) {
      console.error('Error settings cloud storage key', key, value, e);
    }
  }, [mutateStorage, setMutation]);

  const context = useMemo<CloudStorageContextType>(() => ({
    values: storage,
    isLoading: isStorageLoading,
    set,
  }), [set, storage, isStorageLoading]);

  return <CloudStorageContext.Provider {...props} value={context}/>;
}