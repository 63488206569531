import { useLocation } from 'react-router-dom';

import { RedPacketInfoPageTemplate } from '../RedPacketInfoPageTemplate/RedPacketInfoPageTemplate';
import { openTelegramLink } from '../../../utils/common';

export function RedPacketTaskInfoPage() {
  const params = new URLSearchParams(useLocation().search);
  return (
    <RedPacketInfoPageTemplate
      mainButtonText={params.get('mb_text') || ''}
      title={params.get('title') || ''}
      onMainButtonClick={() => {
        openTelegramLink(params.get('open_link') || '');
      }}
      Icon={({ className }) => (
        <img
          className={className}
          src={params.get('image') || ''}
          style={{ minWidth: '100%', minHeight: '100%' }}
        />
      )}
      steps={params.getAll('steps').slice(0, 3)}
    />
  );
}