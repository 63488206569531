import useSWRMutation from 'swr/mutation';

import { useApiClient } from '../../providers/ApiClientProvider/context';
import { useStreaksUserState } from './useStreaksUserState';

/**
 * Returns mutation to complete streaks daily task.
 */
export function useCompleteStreaksDailyTask() {
  const client = useApiClient();
  const userState = useStreaksUserState();

  return useSWRMutation(
    'COMPLETE_STREAKS_DAILY_TASK',
    (_, { arg }: { arg: number }) => client.completeStreaksDailyTask(arg),
    {
      onError(err) {
        console.error('Error completing streaks daily task', err);
      },
      onSuccess() {
        // The task was completed. We should revalidate the user state.
        void userState.mutate();
      },
    },
  );
}