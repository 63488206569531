import useSWR from 'swr';

import { useApiClient } from '../../providers/ApiClientProvider/context';

/**
 * Requests streaks user state.
 */
export function useStreaksUserState() {
  const client = useApiClient();
  return useSWR('STREAKS_USER_STATE', client.getStreaksUserState.bind(client), {
    onError(err) {
      console.error('Error fetching user streaks state', err);
    }
  });
}