import { publicUrl } from '../../utils/url';
import { createSrcSet } from '../../utils/createSrcSet';

type ImageTuple = [
  width: number,
  height: number,
  background: [
    color: string,
    width: number,
    height: number,
    deltaX: number,
    deltaY: number,
  ],
];

interface Artifact {
  img: {
    small: {
      src: string;
      srcSet: string;
      width: number;
      height: number;
      background: {
        color: string;
        src: string;
        width: number;
        height: number;
        deltaX: number;
        deltaY: number;
      };
    };
    large: {
      src: string;
      srcSet: string;
      width: number;
      height: number;
      background: {
        color: string;
        src: string;
        width: number;
        height: number;
        deltaX: number;
        deltaY: number;
      };
    };
  };
  titleKey: string;
  shortDescriptionKey: string;
  descriptionKey: string;
  limitKey?: string;
  limit?: number;
  pocketType: 'lives' | 'nftTickets' | 'usdt';
}

function createImg(
  name: string,
  size: 'small' | 'large',
  [width, height, background]: ImageTuple,
) {
  const baseUrl = `/pocket/artifacts/${name}`;
  const sizeBaseUrl = `${baseUrl}/${size}/${name}-${size}`;

  return {
    src: publicUrl(`${sizeBaseUrl}.png`),
    srcSet: createSrcSet(['', '@2x', '@3x'].map(suffix => {
      return `${sizeBaseUrl}${suffix}.png`;
    })),
    width,
    height,
    background: {
      color: background[0],
      src: `${baseUrl}/bg-blur.png`,
      width: background[1],
      height: background[2],
      deltaX: background[3],
      deltaY: background[4],
    },
  };
}

function createArtifactImg(
  name: string,
  smImage: ImageTuple,
  largeImage: ImageTuple,
) {
  return {
    small: createImg(name, 'small', smImage),
    large: createImg(name, 'large', largeImage),
  };
}

const mappings: Record<string, Artifact> = {
  LIVES: {
    img: createArtifactImg(
      'lives',
      [113, 106, ['#140304', 230, 225, 0, 10]],
      [188, 180, ['#140304', 330, 330, 5, 10]],
    ),
    titleKey: 'PocketPage.LivesArtifactTitle',
    shortDescriptionKey: 'PocketPage.LivesArtifactShortDescription',
    descriptionKey: 'PocketPage.LivesArtifactDescription',
    pocketType: 'lives',
  },
  TICKET: {
    img: createArtifactImg(
      'lottery-ticket',
      [180, 180, ['#030515', 340, 340, -8, 2]],
      [361, 264, ['#030515', 550, 500, -8, 10]],
    ),
    titleKey: 'PocketPage.LotteryTicketTitle',
    shortDescriptionKey: 'PocketPage.LotteryTicketShortDescription',
    descriptionKey: 'PocketPage.LotteryTicketDescription',
    limit: 100,
    limitKey: 'PocketPage.AlertLotteryTicketsLimitReached',
    pocketType: 'nftTickets',
  },
  USDT: {
    img: createArtifactImg(
      'usdt',
      [146, 146, ['#000906', 340, 340, 0, 0]],
      [235, 236, ['#000906', 550, 500, 0, 0]]
    ),
    titleKey: 'PocketPage.USDTTitle',
    shortDescriptionKey: 'PocketPage.USDShortDescription',
    descriptionKey: 'PocketPage.USDTDescription',
    pocketType: 'usdt',
  }
};

export function findArtifact(type: string): Artifact | undefined {
  return mappings[type];
}