import { type PropsWithChildren, useEffect, useMemo } from 'react';

import { ApiClientContext } from './context';
import { ApiClient } from '../../helpers/ApiClient';

export function ApiClientProvider({ baseUrl, initData, children }: PropsWithChildren<{
  initData: string;
  baseUrl: string;
}>) {
  const client = useMemo(() => new ApiClient(baseUrl, initData), [baseUrl, initData]);

  useEffect(() => {
    (window as any).__client = client;
    return () => {
      delete (window as any).__client;
    };
  }, [client]);

  return (
    <ApiClientContext.Provider value={client}>
      {children}
    </ApiClientContext.Provider>
  );
}