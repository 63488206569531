import { useRedPacketState } from './useRedPacketState';
import { useRedPacketAllTasksCompleted } from './useRedPacketAllTasksCompleted';
import { useRedPacketTasks } from './useRedPacketTasks';

export function useRedPacketUnopenedPacketsCount(): number | 'loading' {
  const { data: state } = useRedPacketState();
  const { data: tasks } = useRedPacketTasks();
  const isAllTasksCompleted = useRedPacketAllTasksCompleted();

  if (!state || !tasks || isAllTasksCompleted === 'loading') {
    return 'loading';
  }
  const taskCompleteAll = tasks.find(t => t.type === 'complete-all');

  let count = 0;
  let taskCompleteAllCompleted = false;
  for (const item of state) {
    const { boxToOpen, boxToSendSharing } = item;
    if (boxToSendSharing && boxToSendSharing.sharedAt && (!boxToOpen || !boxToOpen.isOpened)) {
      count++;
    }

    if (taskCompleteAll && item.id === taskCompleteAll.id && item.completed) {
      taskCompleteAllCompleted = true;
    }
  }
  return count + (isAllTasksCompleted && !taskCompleteAllCompleted ? 1 : 0);
}