import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RedPacketInfoPageTemplate } from '../RedPacketInfoPageTemplate/RedPacketInfoPageTemplate';

import { ReactComponent as FriendsIcon } from '../../../svg/img/friends-24.svg';

export function RedPacketInvitePage() {
  const count = Number(new URLSearchParams(useLocation().search).get('count')) || 5;
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <RedPacketInfoPageTemplate
      mainButtonText={t('RedPacketInvitePage.InviteButton')}
      title={t('RedPacketInvitePage.Title', { count })}
      onMainButtonClick={() => {
        navigate('/referral-programs', { replace: true });
      }}
      Icon={FriendsIcon}
      steps={[
        t('RedPacketInvitePage.Step1'),
        t('RedPacketInvitePage.Step2', { count }),
        t('RedPacketInvitePage.Step3'),
      ]}
    />
  );
}