import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { State } from '../State/State';
import { createImage } from '../createImage';
import { useAppStateContext } from '../../../providers/AppStateProvider/context';
import { useTonConnect } from '../../../providers/TonConnectProvider/context';
import { useRegisterWallet } from '../../../hooks/pocket/mutations/useRegisterWallet';
import { RedPacketGoToOnbButton } from '../../RedPacketGoToOnbButton/RedPacketGoToOnbButton';
import { Callout } from '../../../../uilib/Typography/Typography';

import s from './ConnectWalletState.module.scss';

export function ConnectWalletState({ shared }: { shared: boolean }) {
  const { t } = useTranslation();
  const { isLoaderVisible } = useAppStateContext();
  const { wallet, modal } = useTonConnect();
  const { trigger: registerWallet } = useRegisterWallet();

  useEffect(() => {
    if (isLoaderVisible) {
      return;
    }
    const { MainButton: mb, themeParams: tp } = window.Telegram.WebApp;
    const onClick = () => {
      modal.open();
    };
    mb.onClick(onClick);
    mb.setParams({
      is_visible: true,
      is_active: true,
      text: t('RedPacketOpenPacketPage.ConnectWalletStateConnectButton'),
      color: '#372A2E',
      text_color: '#ffffff',
    });

    return () => {
      mb.offClick(onClick);
      mb.setParams({
        is_visible: false,
        color: tp.button_color,
        text_color: tp.button_text_color,
      });
    };
  }, [isLoaderVisible, t, modal]);

  useEffect(() => {
    wallet && registerWallet(wallet.address);
  }, [wallet, registerWallet]);

  return (
    <State
      {...createImage('', { height: '80%', bgWidth: 320 })}
      title={t('RedPacketOpenPacketPage.ConnectWalletStateTitle')}
    >
      {shared && (
        <RedPacketGoToOnbButton className={s.button}>
          <Callout weight="medium">
            {t('RedPacketOpenPacketPage.GoToOnboarding')}
          </Callout>
        </RedPacketGoToOnbButton>
      )}
    </State>
  );
}