import type { PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';

import { Footnote } from '../../../../uilib/Typography/Typography';
import { ChipButton } from '../ChipButton/ChipButton';

import { ReactComponent as WalletIcon } from '../../../../svg/img/wallet.svg';
import s from './Web3Artifacts.module.scss';

interface DataProps {
  walletAddress?: string;
  nftTickets?: Maybe<number>;
  spins?: Maybe<number>;
}

function Container({ children }: PropsWithChildren) {
  return (
    <div className={s.root}>
      <Footnote className={s.title} weight="semi-bold" caps>
        <Trans i18nKey="PocketPage.Web3ArtifactsTitle"/>
      </Footnote>
      <div className={s.items}>
        {children}
      </div>
    </div>
  );
}

function Shimmers() {
  return (
    <Container>
      <ChipButton data="loading"/>
      <ChipButton data="loading"/>
    </Container>
  );
}

function Data({ walletAddress, nftTickets, spins }: DataProps) {
  return (
    <Container>
      {walletAddress && (
        <ChipButton
          data={{
            title: <WalletIcon className={s.walletIcon}/>,
            subtitle: `${walletAddress.slice(0, 4)}...`,
          }}
        />
      )}
      {typeof spins === 'number' && (
        <ChipButton
          data={{
            title: spins,
            subtitle: <Trans i18nKey="PocketPage.Web3ArtifactsSpinsLeft" count={spins}/>,
          }}
        />
      )}
      {typeof nftTickets === 'number' && (
        <ChipButton
          data={{
            title: nftTickets,
            subtitle: <Trans i18nKey="PocketPage.Web3ArtifactsLotteryTickets" count={nftTickets}/>,
          }}
        />
      )}
    </Container>
  );
}

export function Web3Artifacts({ data }: { data: 'loading' | DataProps }) {
  return data === 'loading' ? <Shimmers/> : <Data {...data}/>;
}
