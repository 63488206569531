import { Trans } from 'react-i18next';

import { DefaultShimmer } from '../DefaultShimmer/DefaultShimmer';
import { Progressbar } from '../Progressbar/Progressbar';

import s from './StreaksProgress.module.scss';

export function StreaksProgress({ data, theme }: {
  theme?: 'dark' | 'light';
  data: 'loading' | {
    current: number;
    from: number;
    to: number;
    nextLeague: {
      title: string;
      streakDays: number
    }
  }
}) {
  return (
    <div className={s.root}>
      {data === 'loading' ? (
        <>
          <div className={s.bar}>
            <DefaultShimmer className={s.barItem}/>
            <DefaultShimmer className={s.star}/>
            <DefaultShimmer className={s.barItem}/>
          </div>
          <div className={s.meta}>
            <DefaultShimmer width={100} height={16}/>
            <DefaultShimmer width={40} height={16}/>
          </div>
        </>
      ) : (
        <Progressbar
          {...data}
          theme={theme}
          footer={{
            left: <Trans
              i18nKey="StreaksProgress.DaysLeft"
              values={{ league: data.nextLeague.title }}
            />,
            right: `${data.current} / ${data.nextLeague.streakDays}`,
          }}
        />
      )}
    </div>
  );
}