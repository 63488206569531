import { Trans } from 'react-i18next';
import { ReactNode } from 'react';

import { Footnote, Heading } from '../../../../../../uilib/Typography/Typography';
import { StreaksPanel } from '../../../../../_components/StreaksPanel/StreaksPanel';
import { StreaksProgress } from '../../../../../_components/StreaksProgress/StreaksProgress';
import { DefaultShimmer } from '../../../../../_components/DefaultShimmer/DefaultShimmer';

import s from './Streak.module.scss';

interface League {
  streakDays: number;
  title: string;
}

export interface StreakData {
  streakDays: number;
  currentLeague: League;
  nextLeague?: League;
}

export function Streak({ data }: {
  data: 'loading' | StreakData;
}) {
  let titleNode: ReactNode;
  let leagueNode: ReactNode;
  let progressbarNode: ReactNode;

  if (data === 'loading') {
    titleNode = <DefaultShimmer className={s.titleShimmer} width="80%" height={40}/>;
    leagueNode = <DefaultShimmer className={s.leagueShimmer} width={100} height={24}/>;
    progressbarNode = <StreaksProgress data="loading"/>;
  } else {
    const { streakDays, nextLeague, currentLeague } = data;

    titleNode = (
      <Heading className={s.title} weight="bold">
        <Trans i18nKey="StreaksPage.CurrentStreakTitle" count={streakDays}/>
      </Heading>
    );
    leagueNode = <Footnote className={s.league} weight="medium">{currentLeague.title}</Footnote>;
    if (nextLeague) {
      progressbarNode = (
        <StreaksProgress
          data={{
            from: 0,
            current: streakDays,
            to: nextLeague ? nextLeague.streakDays : streakDays,
            nextLeague,
          }}
        />
      );
    }
  }

  return (
    <StreaksPanel className={s.root}>
      {titleNode}
      {leagueNode}
      {progressbarNode}
    </StreaksPanel>
  );
}
