import { defineCenterWindowUtil } from './defineCenterWindowUtil';

/**
 * Creates useful utilities to use during development.
 */
export function applyWindowUtils() {
  const cs = window.Telegram.WebApp.CloudStorage;

  defineCenterWindowUtil('clearCloudStorage', () => {
    cs.getKeys((err, keys) => {
      if (err) {
        throw new Error(err);
      }
      cs.removeItems(keys as string[], (err, values) => {
        if (err) {
          throw new Error(err);
        }
        console.log('Cloud storage cleared');
      });
    });
  });

  defineCenterWindowUtil('clearHiddenStreaksTasks', () => {
    cs.removeItem('hiddenStreaksTasks');
  });
}