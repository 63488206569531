import { useRedPacketState } from './useRedPacketState';
import { useRedPacketTasks } from './useRedPacketTasks';

export function useRedPacketAllTasksCompleted(): 'loading' | boolean {
  const { data: state } = useRedPacketState();
  const { data: tasks } = useRedPacketTasks();

  return state && tasks
    ? tasks.every(t => {
      if (t.type === 'complete-all') {
        return true;
      }
      const s = state.find(s => s.id === t.id);
      return s && s.completed && s.boxToSendSharing && s.boxToSendSharing.sharedAt;
    })
    : 'loading';
}