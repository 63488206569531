import {
  AnimatePresence,
  motion,
  type MotionProps,
  type Target,
  type TargetAndTransition,
} from 'framer-motion';

import { useIsSetOnce } from '../hooks/useIsSetOnce';

interface Props extends MotionProps {
  initial?: Target;
  animate?: TargetAndTransition;
  exit?: TargetAndTransition;
}

export function DefaultAnimatePresence({
  children,
  animate,
  initial,
  exit,
  ...rest
}: Props) {
  const hiddenOnce = useIsSetOnce(!children);

  return (
    <AnimatePresence>
      {children && (
        <motion.div
          key="inner"
          {...rest}
          initial={hiddenOnce ? { height: 0, opacity: 0, scale: 0.9, ...initial } : false}
          animate={{ height: 'auto', opacity: 1, scale: 1, ...animate }}
          exit={{ height: 0, opacity: 0, scale: 0.9, ...exit }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}