import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { WithColoredUI } from '../WithColoredUI';
import { publicUrl } from '../../../utils/url';
import { createSrcSet } from '../../../utils/createSrcSet';
import { Callout, Heading } from '../../../uilib/Typography/Typography';
import { RedPacketTaskReachLeague } from '../RedPacketTaskReachLeague/RedPacketTaskReachLeague';
import { RedPacketTaskPremium } from '../RedPacketTaskPremium/RedPacketTaskPremium';
import {
  RedPacketTaskInviteFriends,
} from '../RedPacketTaskInviteFriends/RedPacketTaskInviteFriends';
import { RedPacketTaskShimmer } from '../RedPacketTask/RedPacketTask';
import { findLeagueById } from '../../leagues/leagues';
import { RedPacketTaskSecret } from '../RedPacketTaskSecret/RedPacketTaskSecret';
import { useRedPacketTasks } from '../../hooks/red-packet/useRedPacketTasks';
import { useRedPacketState } from '../../hooks/red-packet/useRedPacketState';
import {
  useCloudStorageKey,
  useIsCloudStorageLoading,
} from '../../providers/CloudStorageProvider/context';
import { DefaultAnimatePresence } from '../DefaultAnimatePresence';
import { telegramUtils } from '../../../services/utils';
import { useTonConnect } from '../../providers/TonConnectProvider/context';
import { useRegisterWallet } from '../../hooks/pocket/mutations/useRegisterWallet';
import { useAppStateContext } from '../../providers/AppStateProvider/context';
import { RedPacketGoToOnbButton } from '../RedPacketGoToOnbButton/RedPacketGoToOnbButton';
import { RedPacketTaskOpenApp } from '../RedPacketTaskOpenApp/RedPacketTaskOpenApp';
import { RedPacketTaskCompleteAll } from '../RedPacketTaskCompleteAll/RedPacketTaskCompleteAll';
import { useIsRedPacketOver } from '../../hooks/flags/useIsRedPacketOver';
import {
  useRedPacketUnopenedPacketsCount,
} from '../../hooks/red-packet/useRedPacketUnopenedPacketsCount';
import { useRedPacketAllTasksCompleted } from '../../hooks/red-packet/useRedPacketAllTasksCompleted';

import { ReactComponent as ChevronRightIcon } from '../../../svg/img/chevron-right18.svg';
import s from './RedPacketPage.module.scss';

export function RedPacketPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: tasks } = useRedPacketTasks();
  const { data: state } = useRedPacketState();
  const isRedPacketOver = useIsRedPacketOver();
  const { wallet, modal } = useTonConnect();
  const { trigger: registerWallet } = useRegisterWallet();
  const { isLoaderVisible } = useAppStateContext();
  const unopenedPacketsCount = useRedPacketUnopenedPacketsCount();
  const isAllTasksCompleted = useRedPacketAllTasksCompleted();

  const [hiddenTasks, setHiddenTasks] = useCloudStorageKey('redPacketHiddenTasks');
  const isCSLoading = useIsCloudStorageLoading();
  const hiddenTasksIds = useMemo(() => {
    return hiddenTasks
      ? hiddenTasks.split(',').map(Number)
      : [];
  }, [hiddenTasks]);
  const hideTask = useCallback((id: number) => {
    setHiddenTasks([...hiddenTasksIds, id].join(','));
  }, [setHiddenTasks, hiddenTasksIds]);

  const displayedTasks = useMemo(() => {
    return isCSLoading || !state || !tasks || isAllTasksCompleted === 'loading'
      ? 'loading'
      : tasks
        .map((task, idx) => {
          if (hiddenTasksIds.includes(task.id)) {
            return;
          }

          const taskState = state.find(item => item.id === task.id);
          if (task.type === 'complete-all') {
            if (!isRedPacketOver || !isAllTasksCompleted) {
              return;
            }
          } else if (isRedPacketOver && (
            !taskState
            || !taskState.boxToSendSharing
            || !taskState.boxToSendSharing.sharedAt
            || (taskState.boxToOpen && taskState.boxToOpen.isOpened)
          )) {
            return;
          }

          const sharedProps = {
            ...taskState,
            onHide() {
              hideTask(task.id);
            },
            id: task.id,
          };
          if (task.type === 'premium') {
            return <RedPacketTaskPremium key={idx} {...sharedProps}/>;
          }
          if (task.type === 'league') {
            const league = findLeagueById(task.data.id);
            if (league) {
              return (
                <RedPacketTaskReachLeague
                  {...sharedProps}
                  key={idx}
                  days={task.data.days}
                  titleKey={league.redPacketTaskTitleKey}
                  icon={league.img}
                  leagueId={task.data.id}
                />
              );
            }
          }
          if (task.type === 'complete-all') {
            return <RedPacketTaskCompleteAll key={idx} {...sharedProps}/>;
          }
          if (task.type === 'referrals') {
            return (
              <RedPacketTaskInviteFriends
                key={idx}
                count={task.data.count}
                {...sharedProps}
              />
            );
          }
          if (task.type === 'secret') {
            return <RedPacketTaskSecret key={idx} {...sharedProps}/>;
          }
          if (task.type === 'open-app') {
            return (
              <RedPacketTaskOpenApp
                key={idx}
                {...sharedProps}
                title={task.title}
                description={task.description}
                onboarding={task.onboarding}
                app={task.data}
              />
            );
          }
        })
        .map((item, idx) => (
          <DefaultAnimatePresence
            initial={{ marginTop: -12 }}
            animate={{ marginTop: 0 }}
            exit={{ marginTop: -12 }}
            key={`red-packet-task-${idx}`}
          >
            {item}
          </DefaultAnimatePresence>
        ));
  }, [isCSLoading, tasks, state, hiddenTasksIds, hideTask, isRedPacketOver, isAllTasksCompleted]);

  useEffect(() => {
    if (
      isRedPacketOver
      || wallet
      || isLoaderVisible
      || !tasks
      || !state
      // We are showing wallet requirement only in case, the user has at least one incomplete task.
      || tasks.every(t => {
        const taskState = state.find(s => s.id === t.id);
        return taskState && taskState.completed;
      })) {
      return;
    }
    const { MainButton: mb, themeParams: tp } = window.Telegram.WebApp;

    const onClick = () => {
      modal.open();
    };
    mb.onClick(onClick);
    mb.setParams({
      is_visible: true,
      is_active: true,
      has_shine_effect: true,
      text: t('RedPacketPage.ConnectWallet'),
      color: '#372A2E',
      text_color: '#ffffff',
    });

    return () => {
      mb.offClick(onClick);
      mb.setParams({
        is_visible: false,
        color: tp.button_color,
        text_color: tp.button_text_color,
      });
    };
  }, [t, wallet, modal, isLoaderVisible, tasks, state, isRedPacketOver]);

  useEffect(() => {
    !isRedPacketOver && wallet && registerWallet(wallet.address);
  }, [wallet, registerWallet, isRedPacketOver]);

  useEffect(() => {
    if (isRedPacketOver && typeof unopenedPacketsCount === 'number' && !unopenedPacketsCount) {
      navigate('/', { replace: true });
    }
  }, [navigate, unopenedPacketsCount, isRedPacketOver]);

  const imageName = `envelope${isRedPacketOver ? '-over' : ''}`;

  return (
    <WithColoredUI color="#251517">
      <div className={s.root}>
        <div className={s.inner}>
          <section className={s.image}>
            <img
              draggable={false}
              className={s.imageBg}
              alt=""
              src={publicUrl(`/red-packet/${imageName}/bg.png`)}
              width={380}
              height={330}
            />
            <img
              draggable={false}
              className={s.imageImg}
              alt=""
              width={192}
              height={200}
              src={publicUrl(`/red-packet/${imageName}/${imageName}.png`)}
              srcSet={createSrcSet(['', '@2x', '@3x'].map(suffix => {
                return publicUrl(`/red-packet/${imageName}/${imageName}${suffix}.png`);
              }))}
            />
          </section>
          <section className={s.titleContainer}>
            <Heading className={s.title} weight="bold">
              <Trans i18nKey={`RedPacketPage.Title${isRedPacketOver ? 'CelebrationOver' : ''}`}/>
            </Heading>
            <Callout className={s.subtitle} weight="regular">
              <Trans i18nKey={`RedPacketPage.Subtitle${isRedPacketOver ? 'CelebrationOver' : ''}`}/>
            </Callout>
            {!isRedPacketOver && (
              <RedPacketGoToOnbButton>
                <Callout weight="medium">
                  <Trans i18nKey="RedPacketPage.GoToOnboarding"/>
                </Callout>
              </RedPacketGoToOnbButton>
            )}
          </section>
          <section className={s.tasks}>
            {displayedTasks === 'loading'
              ? new Array(2).fill(0).map((_, i) => (
                <RedPacketTaskShimmer key={i}/>
              ))
              : displayedTasks}
          </section>
          <div className={s.divider}/>
          <a
            href="https://telegra.ph/Red-Packets-Rules-for-Telegram-Apps-Center-01-28"
            draggable={false}
            className={s.terms}
            onClick={(e) => {
              e.preventDefault();
              telegramUtils.openLink('https://telegra.ph/Red-Packets-Rules-for-Telegram-Apps-Center-01-28');
            }}
          >
            <Trans i18nKey="RedPacketPage.Terms"/>
            <ChevronRightIcon/>
          </a>
        </div>
      </div>
    </WithColoredUI>
  );
}