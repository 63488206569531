import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import mixpanel from 'mixpanel-browser';

import { RedPacketTask, type RedPacketTaskProps } from '../RedPacketTask/RedPacketTask';
import { TWAApplicationCard } from '../../../uilib/ApplicationCard/TWAApplicationCard';
import { openTelegramLink } from '../../../utils/common';

export function RedPacketTaskOpenApp({
  title: taskTitle,
  description: taskDescription,
  onboarding,
  app: {
    id,
    title,
    url,
    webappUrl,
    icon,
    editorsChoice,
    description,
    startParam,
  },
  ...rest
}: {
  title?: Maybe<string>;
  description?: Maybe<string>;
  onboarding?: Maybe<{
    title?: Maybe<string>;
    steps: string[];
  }>;
  app: {
    id: number;
    title?: Maybe<string>;
    editorsChoice?: Maybe<boolean>;
    description?: Maybe<string>;
    webappUrl?: Maybe<string>;
    url?: Maybe<string>;
    icon: { url: string; name?: Maybe<string> };
    startParam?: Maybe<string>;
  };
} & Pick<RedPacketTaskProps, 'onHide' | 'id'>) {
  const { t } = useTranslation();
  const onClick = useCallback(() => {
    mixpanel.track('App Launch', {
      app_name: title,
      app_id: id,
      section: 'Streaks',
      task_type: 'red-packet',
    });

    const appUrl = webappUrl || url;
    if (appUrl) {
      const urlObj = new URL(appUrl);
      if (startParam) {
        urlObj.searchParams.set(webappUrl ? 'startapp' : 'start', startParam);
      }
      openTelegramLink(urlObj.toString());
    }
  }, [title, id, webappUrl, url, startParam]);

  return (
    <RedPacketTask
      {...rest}
      type="completable"
      title={taskTitle || t('RedPacketTaskOpenApp.Title')}
      text={taskDescription || t('RedPacketTaskOpenApp.Text')}
      notCompletedErrorMessage={t('RedPacketTaskOpenApp.AlertTaskNotCompleted')}
      infoPath={onboarding
        ? `/red-packet/task-info?${new URLSearchParams([
          ['image', icon.url],
          [
            'title',
            onboarding.title
            || title
            || t('RedPacketTaskOpenApp.TaskInfoTitle', { app: title || '' }),
          ],
          ['open_link', webappUrl || url || ''],
          ['mb_text', t('RedPacketTaskOpenApp.TaskInfoMainButtonText', { app: title || '' })],
          ...onboarding.steps.map(step => ['steps', step]),
        ]).toString()}`
        : undefined}
    >
      <TWAApplicationCard
        title={title || undefined}
        editorsChoice={editorsChoice || undefined}
        description={description || undefined}
        img={{ src: icon.url, name: icon.name || '' }}
        overrideOnClick={onClick}
        onButtonClick={onClick}
      />
    </RedPacketTask>
  );
}