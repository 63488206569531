import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { WithColoredUI } from '../WithColoredUI';
import { OpeningState } from './OpeningState/OpeningState';
import { PrizeState } from './PrizeState/PrizeState';
import type { RedPacketTaskPacketType } from '../../helpers/types';
import { ErrorState, type ErrorStateType } from './ErrorState/ErrorState';
import { ApiError } from '../../../shared/http/ApiError';
import { useTonConnect } from '../../providers/TonConnectProvider/context';
import { ConnectWalletState } from './ConnectWalletState/ConnectWalletState';

import s from './RedPacketOpenPacketPage.module.scss';

export function RedPacketOpenPacketPage() {
  const searchParams = new URLSearchParams(useLocation().search);
  const hash = searchParams.get('hash') || '';
  const taskId = Number(searchParams.get('task_id'));
  const taskType = searchParams.get('task_type');
  const packet: ['hash', string] | ['task', number, string?] | undefined = hash
    ? ['hash', hash]
    : taskId
      ? ['task', taskId, taskType || undefined]
      : undefined;
  const isShared = !!hash;
  const navigate = useNavigate();
  const { wallet, disconnectWallet } = useTonConnect();

  const [error, setError] = useState<ErrorStateType>();
  const [data, setData] = useState<{
    type: RedPacketTaskPacketType;
    value: number;
  }>();

  useEffect(() => {
    !hash && !taskId && navigate('/red-packets');
  }, [hash, taskId, navigate]);

  const onError = useCallback((e: unknown) => {
    if (e instanceof ApiError) {
      if (e.hasErrorCode('ERR_LOOT_BOX_EXPIRED')) {
        return setError('packetExpired');
      }
      if (e.hasErrorCode('ERR_LOOT_BOX_CAN_NOT_BE_SHARED_THE_SAME_USER')) {
        return setError('packetFromSameUser');
      }
      if (e.hasOneOfErrorCodes(['ERR_LOOT_BOX_ALREADY_ASSIGNED', 'ERR_LOOT_BOX_ALREADY_OPENED'])) {
        return setError('packetAlreadyOpened');
      }
      if (e.hasOneOfErrorCodes(['ERR_LOOT_BOX_ITEM_NOT_FOUND', 'ERR_LOOT_BOX_SHARING_NOT_FOUND'])) {
        return setError('packetNotFound');
      }
      if (e.hasErrorCode('ERR_USER_HAS_NO_WALLET')) {
        return disconnectWallet();
      }
    }
    setError('unknown');
  }, [disconnectWallet]);

  return (
    <WithColoredUI color="#251517">
      <div className={s.root}>
        <AnimatePresence>
          {!wallet && (
            <motion.div
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ position: 'absolute', top: 0, opacity: 0 }}
            >
              <ConnectWalletState shared={isShared}/>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {(packet && wallet && !data && !error) && (
            <motion.div
              className={s.stateWrapper}
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ position: 'absolute', top: 0, opacity: 0 }}
            >
              <OpeningState
                packet={packet}
                onError={onError}
                onCompleted={setData}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {(error || data) && wallet && (
            <motion.div
              className={s.stateWrapper}
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
            >
              {data
                ? <PrizeState shared={isShared} {...data}/>
                : error
                  ? <ErrorState shared={isShared} type={error}/>
                  : null}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </WithColoredUI>
  );
}