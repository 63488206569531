import useSWRImmutable from 'swr/immutable';
import { useEffect, useState } from 'react';
import FlagProvider, { useFlagsStatus } from '@unleash/proxy-client-react';

import { App } from './App';
import { getCategories, getConfig, getRankings } from '../../utils/api/requests';
import { getMainPageBlockConfig, getNavigationConfig } from '../../utils/api/config';
import { ApiClientProvider } from '../providers/ApiClientProvider/ApiClientProvider';
import { useApiClient } from '../providers/ApiClientProvider/context';
import { LocaleProvider } from '../providers/LocaleProvider/LocaleProvider';
import { useLocaleContext } from '../providers/LocaleProvider/context';
import { AppStateProvider } from '../providers/AppStateProvider/AppStateProvider';
import { CloudStorageProvider } from '../providers/CloudStorageProvider/CloudStorageProvider';

interface InnerProps {
  tonConnectReturnBaseUrl: string;
  tonConnectManifestUrl: string;
}

interface RootProps extends InnerProps {
  apiBaseUrl: string;
  unleashUrl: string;
  unleashClientKey: string;
}

function Inner({ tonConnectReturnBaseUrl, tonConnectManifestUrl }: InnerProps) {
  const client = useApiClient();
  const appConfig = useSWRImmutable('appConfig', getConfig);
  const appsRanking = useSWRImmutable('appsRanking', getRankings);
  const categories = useSWRImmutable('categories', getCategories);
  const mainPageBlockConfig = useSWRImmutable('mainPageBlocks', getMainPageBlockConfig);
  const navigationConfig = useSWRImmutable('navigationConfig', getNavigationConfig);
  const signIn = useSWRImmutable('sign-in', client.signIn.bind(client), {
    onError(err) {
      console.error('Sign in failed', err);
    },
  });
  const { isLoading: isTranslationsLoading } = useLocaleContext();
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const { flagsReady, flagsError } = useFlagsStatus();

  const isInitialDataLoaded = (!signIn.isLoading && !signIn.error)
    && !isTranslationsLoading
    && !navigationConfig.isLoading
    && !mainPageBlockConfig.isLoading
    // We wait for Unleash flags to be loaded. We will probably have some error, but in this case
    // flagsReady will be false. That's why we use "|| flagsError".
    && (flagsReady || flagsError);

  useEffect(() => {
    if (!isInitialDataLoaded) {
      return;
    }

    const loader = document.querySelector('.appLoader');
    if (!loader) {
      setIsLoaderVisible(false);
      return;
    }

    // We are hiding the loader in 300ms because we need some time for children to be rendered.
    let timeoutId = setTimeout(() => {
      loader.classList.add('fading');

      timeoutId = setTimeout(() => {
        loader.remove();
        setIsLoaderVisible(false);
      }, 300);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isInitialDataLoaded]);

  return (
    <AppStateProvider isLoaderVisible={isLoaderVisible} isInitialDataLoaded={isInitialDataLoaded}>
      <App
        appConfig={appConfig.data?.data}
        navigationConfig={navigationConfig.data}
        mainPageBlockConfig={mainPageBlockConfig?.data ?? []}
        categories={categories?.data?.data}
        appsRanking={appsRanking?.data || []}
        tonConnectReturnBaseUrl={tonConnectReturnBaseUrl}
        tonConnectManifestUrl={tonConnectManifestUrl}
      />
    </AppStateProvider>
  );
}

/**
 * The application root component.
 */
export function Root({ apiBaseUrl, unleashUrl, unleashClientKey, ...rest }: RootProps) {
  return (
    <FlagProvider
      config={{
        url: unleashUrl,
        disableRefresh: true,
        clientKey: unleashClientKey,
        appName: 'tapps-center-frontend',
      }}
    >
      <CloudStorageProvider>
        <LocaleProvider>
          <ApiClientProvider initData={window.Telegram.WebApp.initData} baseUrl={apiBaseUrl}>
            <Inner {...rest}/>
          </ApiClientProvider>
        </LocaleProvider>
      </CloudStorageProvider>
    </FlagProvider>
  );
}
