import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useDataContext } from '../../../dataSource/DataContext/DataContext';
import { useAlertBehaviour } from '../../../hooks/useAlertBehaviour';
import { useScrollRestoration } from '../../../hooks/useScrollRestoration';
import { Alert } from '../../../uilib/Alert/Alert';
import { TWAAppList } from '../../../uilib/AppList/TWAAppList';
import { TWAFooter } from '../../../uilib/Footer/TWAFooter';
import { Heading } from '../../../uilib/Typography/Typography';
import { WithColoredUI } from '../../_components/WithColoredUI';
import local from './styles.module.scss';
import page from '../page.module.scss';

export default function CategoryPage() {
  const { categories } = useDataContext();
  const { category: categoryFromPath } = useParams();
  const activeCategory = categories.find(
    (category) => category?.attributes?.path === categoryFromPath,
  );
  const { ref } = useScrollRestoration(activeCategory?.attributes.path || 'index', {
    debounceTime: 200,
  });

  const { isVisible, handleClose, isLoading } = useAlertBehaviour({ category: activeCategory });

  // TODO: This is a crutch for the red packets release.
  const navigate = useNavigate();
  useEffect(() => {
    if (categoryFromPath === 'red-packet') {
      navigate('/', { replace: true });
    }
  }, [categoryFromPath, navigate]);

  return (
    <WithColoredUI>
      <main ref={ref} className={page.main}>
        <div className={local.root}>
          <Heading
            type={'h2'}
            weight={'bold'}
          >
            {activeCategory?.attributes.title}
          </Heading>
        </div>
        {
          isVisible && (
            <Alert
              alert={activeCategory?.attributes?.alert?.data}
              onClose={handleClose}
            />
          )
        }
        {
          activeCategory?.attributes.title && (
            <TWAAppList
              isLoading={isLoading}
              category={activeCategory?.attributes.title}
            />
          )
        }
        <div className={page.footerWrapper}>
          <TWAFooter/>
        </div>
      </main>
    </WithColoredUI>
  );
}
