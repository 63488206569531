import { Section } from '../Section/Section';
import { DefaultAnimatePresence } from '../../../../_components/DefaultAnimatePresence';
import { useIsSetOnce } from '../../../../hooks/useIsSetOnce';
import { useData } from './useData';
import { Tasks } from './Tasks/Tasks';

export function AdditionalTasks() {
  const data = useData();
  const vanishedOnce = useIsSetOnce(!data);

  return (
    <DefaultAnimatePresence
      initial={{ marginTop: -12 }}
      animate={{ marginTop: 0 }}
      exit={{ marginTop: -12 }}
    >
      {(data && !vanishedOnce && data.length > 0) && (
        <Section>
          <Tasks data={data}/>
        </Section>
      )}
    </DefaultAnimatePresence>
  );
}
