import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { WithColoredUI } from '../WithColoredUI';
import { publicUrl } from '../../../utils/url';
import { createSrcSet } from '../../../utils/createSrcSet';
import { useAppStateContext } from '../../providers/AppStateProvider/context';
import { Body, Heading } from '../../../uilib/Typography/Typography';

import s from './RedPacketOnboardingPage.module.scss';

export function RedPacketOnboardingPage() {
  const { MainButton: mb } = window.Telegram.WebApp;
  const { t } = useTranslation();
  const { isLoaderVisible } = useAppStateContext();
  const items = useMemo(() => {
    const createItem = (
      num: number,
      img?: { width?: number, x?: number, y?: number },
      bg?: { width?: number },
    ) => {
      return [
        {
          img: {
            src: publicUrl(`/red-packet/onboarding-${num}/onboarding-${num}.png`),
            srcSet: createSrcSet(['', '@2x', '@3x'].map(suffix => {
              return publicUrl(`/red-packet/onboarding-${num}/onboarding-${num}${suffix}.png`);
            })),
            width: 400,
            ...img,
          },
          bg: {
            src: publicUrl(`/red-packet/onboarding-${num}/bg.png`),
            width: 700,
            ...bg,
          },
        },
        t(`RedPacketOnboardingPage.Title${num}`),
        t(`RedPacketOnboardingPage.Text${num}`),
      ] as const;
    };

    return [
      createItem(1),
      createItem(2, { x: 30, y: 20 }),
      createItem(3, { width: 700, y: 30 }, { width: 900 }),
      createItem(4, undefined, { width: 800 }),
      createItem(5, undefined, { width: 800 }),
    ];
  }, [t]);
  const [slide, setSlide] = useState(0);

  const navigate = useNavigate();

  const itemsCount = items.length;
  const isLastSlide = slide === itemsCount - 1;

  useEffect(() => {
    const onClick = () => {
      if (isLastSlide) {
        navigate(-1)
      } else {
        setSlide(s => s + 1);
      }
    };
    mb.onClick(onClick);
    return () => {
      mb.offClick(onClick);
    };
  }, [mb, isLastSlide, navigate]);

  useEffect(() => {
    // Not showing the main button until the loader is visible.
    if (isLoaderVisible) {
      return;
    }
    mb.setParams({
      is_active: true,
      is_visible: true,
      text: t(`RedPacketOnboardingPage.Button${isLastSlide ? 'Complete' : 'Next'}`),
    });
  }, [mb, isLoaderVisible, t, isLastSlide]);

  useEffect(() => {
    return () => {
      mb.hide();
    };
  }, [mb]);

  const [
    {
      img: { y, x, ...img },
      bg,
    },
    title,
    text,
  ] = items[slide];

  return (
    <WithColoredUI color="bg_color">
      <div className={s.root}>
        <section className={s.imageContainer}>
          <img
            key={`background-${slide}`}
            draggable={false}
            className={s.imageBg}
            alt=""
            {...bg}
          />
          <img
            key={`image-${slide}`}
            draggable={false}
            className={s.imageImg}
            alt=""
            {...img}
            style={{
              transform: `translate(${x ? `calc(-50% + ${x}px)` : '-50%'}, ${y ? `calc(-50% + ${y}px)` : '-50%'})`,
            }}
          />
        </section>
        <div className={s.inner}>
          <Heading className={s.title} weight="semi-bold" type="h2">
            {title}
          </Heading>
          <Body weight="regular">{text}</Body>
          <div className={s.pagination}>
            <div className={s.paginationLayout}/>
            {new Array(itemsCount).fill(0).map((_, i) => (
              <div
                key={i}
                className={cn(s.paginationItem, i === slide && s.paginationItemActive)}
              />
            ))}
          </div>
        </div>
      </div>
    </WithColoredUI>
  );
}