import { useMemo } from 'react';

import { useUTCDaysDiff } from '../../../../../hooks/dates/useUTCDaysDiff';
import { useCompletionPanelHidden } from './useCompletionPanelHidden';
import { useUserPocket } from '../../../../../hooks/pocket/queries/useUserPocket';
import { useStreaksUserState } from '../../../../../hooks/streaks/useStreaksUserState';
import { formatTask, FormattedTask } from '../../../utils/formatTask';
import { useStreaksTasks } from '../../../../../hooks/streaks/useStreaksTasks';
import { usePocketCollections } from '../../../../../hooks/pocket/queries/usePocketCollections';
import {
  useCloudStorageKey,
  useIsCloudStorageLoading,
} from '../../../../../providers/CloudStorageProvider/context';
import { useNow } from '../../../../../hooks/dates/useNow';
import { useFlag } from '@unleash/proxy-client-react';

type Result =
  | [status: 'loading']
  | [status: 'task-completed']
  | [status: 'streak-almost-lost', livesToRestore: number, shouldPurchase: boolean]
  | [status: 'streak-lost']
  | [status: 'completed', task: FormattedTask]
  | [status: 'verifying', task: FormattedTask]
  | undefined;

export function useData(): Result {
  const { data: streaksTasks, isLoading: isStreaksTasksLoading } = useStreaksTasks();
  const { data: userState, isLoading: isUserStateLoading } = useStreaksUserState();
  const { data: userPocket, isLoading: isUserPocketLoading } = useUserPocket();
  const { data: collections, isLoading: isCollectionsLoading } = usePocketCollections();
  const { isHidden: isPanelHidden, isLoading: isPanelHiddenLoading } = useCompletionPanelHidden();
  const streakResetAllowed = useFlag('allowStreakReset');

  const task = useMemo(() => {
    return isStreaksTasksLoading
      ? 'loading'
      : streaksTasks && streaksTasks.main
        ? formatTask(streaksTasks.main)
        : undefined;
  }, [streaksTasks, isStreaksTasksLoading]);
  const daysDiff = useUTCDaysDiff();
  const now = useNow();

  const isCSLoading = useIsCloudStorageLoading();
  const [taskOpenedAtStr] = useCloudStorageKey('dailyTaskOpenedAt');
  const isVerifyingCompletion = useMemo(() => {
    return isCSLoading
      ? 'loading'
      : taskOpenedAtStr
        // At least 10 seconds should elapse to show completion.
        ? new Date(taskOpenedAtStr).getTime() + 10_000 >= now.getTime()
        : false;
  }, [isCSLoading, taskOpenedAtStr, now]);

  return useMemo<Result>(() => {
    if (task === 'loading' || isUserStateLoading) {
      return ['loading'];
    }

    const appState: Result = task ? ['completed', task] : undefined;

    // No user data fetched. The only thing we can do here is just to display the daily task.
    if (!userState) {
      return appState;
    }

    // User didn't complete the daily task, display it.
    const { latestCompletedTaskDate } = userState;
    if (!latestCompletedTaskDate) {
      return appState;
    }

    // Now, compute how many days elapsed since the last task completion date.
    const daysElapsed = -daysDiff(latestCompletedTaskDate);

    // No days elapsed, it means the task was completed today.
    if (!daysElapsed) {
      return isPanelHiddenLoading
        ? ['loading']
        : isPanelHidden
          // When the task was completed and the panel is hidden, we are not displaying it.
          ? undefined
          : isVerifyingCompletion === 'loading'
            // We need task application open date to determine which state should be displayed
            // exactly.
            ? undefined
            : isVerifyingCompletion && task
              ? ['verifying', task]
              : ['task-completed'];
    }

    // 1 day elapsed, time to complete the daily task.
    if (daysElapsed === 1) {
      return appState;
    }

    // Before showing the user the information about his streak was lost or almost lost, we
    // should retrieve his pocket and collections information.
    if (isUserPocketLoading) {
      return ['loading'];
    }

    // We were unable the fetch the user pocket. It is probably better not to display anything
    // because we don't really know if the current lives count is enough to restore the streak.
    if (!userPocket) {
      // TODO: Should probably display some information panel with error. Otherwise, the user
      //  may have some confusion.
      return;
    }

    // The streak was almost lost.
    const daysToRestore = daysElapsed - 1;
    const { lives } = userPocket;

    // At the moment, the user already has enough lives to restore the streak. We are going to
    // display how much it will cost him.
    if (lives >= daysToRestore) {
      return ['streak-almost-lost', daysToRestore, false];
    }

    const livesToPurchase = daysToRestore - lives;
    // TODO: Remove after release.
    if (!streakResetAllowed) {
      return ['streak-almost-lost', livesToPurchase, true];
    }

    // For all other cases, we need information about lives price.
    if (isCollectionsLoading) {
      return ['loading'];
    }

    // At the moment, the user has not enough lives to restore the streak. Then we should if the
    // user has enough points to buy lives and restore the streak.
    const livesArtifact = (collections || []).find(c => c.type === 'LIVES');
    if (livesArtifact) {
      // Checking if the user has enough points to purchase the required count of lives.
      if (userPocket.points >= livesToPurchase * livesArtifact.price) {
        return ['streak-almost-lost', livesToPurchase, true];
      }
    }
    return ['streak-lost'];
  }, [
    task,
    userState,
    isUserStateLoading,
    userPocket,
    isUserPocketLoading,
    isPanelHiddenLoading,
    isPanelHidden,
    isVerifyingCompletion,
    daysDiff,
    collections,
    isCollectionsLoading,
    streakResetAllowed,
  ]);
}