import useSWR, { type SWRConfiguration, type SWRResponse } from 'swr';

import { useApiClient } from '../../../providers/ApiClientProvider/context';

interface Result {
  points: number;
  boosts?: Maybe<number>;
  lives: number;
  spins?: Maybe<number>;
  nftTickets?: Maybe<number>;
  usdt?: Maybe<number>;
}

/**
 * Requests user pocket information.
 */
export function useUserPocket(options?: SWRConfiguration<Result>): SWRResponse<Result> {
  const client = useApiClient();
  return useSWR<Result>('USER_POCKET', client.getUserPocket.bind(client), {
    ...options,
    onError(err, key, config) {
      console.error('Error fetching user pocket', err);
      options && options.onError && options.onError(err, key, config);
    },
  });
}