import type { PropsWithChildren } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { Heading } from '../../../../uilib/Typography/Typography';

import s from './State.module.scss';

export function State({
  image,
  bg,
  title,
  children,
}: PropsWithChildren<{
  showImage?: boolean;
  showTitle?: boolean;
  title: string;
  image: {
    src: string;
    srcSet: string;
    width?: number | string;
    height?: number | string;
    x?: number;
    y?: number;
  };
  bg: {
    src: string;
    width: number | string;
    height?: number | string;
  };
}>) {
  const { x, y, ...img } = image;
  return (
    <div className={s.root}>
      <AnimatePresence>
        <motion.section
          className={s.image}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.3 }}
          animate={{ opacity: 1 }}
        >
          <img draggable={false} className={s.imageBg} alt="" {...bg}/>
          <img
            draggable={false}
            className={s.imageImg}
            alt=""
            style={{
              transform: `translate(${x ? `calc(-50% + ${x}px)` : '-50%'}, ${y ? `calc(-50% + ${y}px)` : '-50%'})`,
            }}
            {...img}
          />
        </motion.section>
      </AnimatePresence>
      <section className={s.inner}>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            transition={{ delay: 0.8 }}
            animate={{ opacity: 1 }}
          >
            <Heading className={s.title} weight="bold">
              {title}
            </Heading>
          </motion.div>
        </AnimatePresence>
        <AnimatePresence>
          <motion.div
            className={s.content}
            initial={{ opacity: 0 }}
            transition={{ delay: 1.3 }}
            animate={{ opacity: 1 }}
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </section>
    </div>
  );
}