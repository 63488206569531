import { type ComponentType, useEffect } from 'react';

import { WithColoredUI } from '../WithColoredUI';
import { Body, Heading } from '../../../uilib/Typography/Typography';

import { ReactComponent as OneIcon } from '../../../svg/img/circle-number-one-40.svg';
import { ReactComponent as TwoIcon } from '../../../svg/img/circle-number-two-40.svg';
import { ReactComponent as ThreeIcon } from '../../../svg/img/circle-number-three-40.svg';
import s from './RedPacketInfoPageTemplate.module.scss';

export function RedPacketInfoPageTemplate({
  mainButtonText,
  onMainButtonClick,
  steps,
  title,
  Icon,
}: {
  Icon: ComponentType<{ className?: string }>;
  title: string;
  steps: string[];
  mainButtonText: string;
  onMainButtonClick(): void;
}) {
  const icons = [OneIcon, TwoIcon, ThreeIcon];
  const { MainButton: mb } = window.Telegram.WebApp;

  useEffect(() => {
    mb.setParams({
      text: mainButtonText,
      is_visible: true,
      is_active: true,
      has_shine_effect: true,
    });
    return mb.hide;
  }, [mainButtonText, onMainButtonClick, mb]);

  useEffect(() => {
    mb.onClick(onMainButtonClick);
    return () => {
      mb.offClick(onMainButtonClick);
    };
  });

  return (
    <WithColoredUI color="bg_color">
      <div className={s.root}>
        <div className={s.inner}>
          <section className={s.imageSection}>
            <div className={s.imageBlock}>
              <Icon className={s.image}/>
            </div>
          </section>
          <Heading className={s.title} weight="bold">
            {title}
          </Heading>
          <div className={s.list}>
            {steps.map((text, idx) => {
              const Icon = icons[idx];
              return (
                <div className={s.listItem} key={idx}>
                  <Icon className={s.listItemNum}/>
                  <Body className={s.listItemText} weight="regular">{text}</Body>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </WithColoredUI>
  );
}