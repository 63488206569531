import { Trans } from 'react-i18next';

import { Section } from '../Section/Section';
import { StreaksButton } from '../../../../_components/StreaksButton/StreaksButton';
import { telegramUtils } from '../../../../../services/utils';
import { Body } from '../../../../../uilib/Typography/Typography';

import s from './Links.module.scss';

export function Links() {
  return (
    <Section>
      <StreaksButton
        className={s.button}
        onClick={() => {
          telegramUtils.openLink('https://telegra.ph/Official-Rules-for-the-Streak-System-in-Telegram-Apps-Center-11-13', {
            tryInstantView: true,
          });
        }}
      >
        <Body weight="semi-bold">
          <span className={s.text}>
            <Trans i18nKey="StreaksPage.Terms"/>
          </span>
          <svg
            className={s.arrow}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              opacity="0.9"
              d="M12.5464 9.00024C12.5464 9.2605 12.4575 9.47632 12.248 9.67944L7.36035 14.4592C7.19531 14.6243 6.99854 14.7068 6.76367 14.7068C6.2876 14.7068 5.90039 14.3259 5.90039 13.8499C5.90039 13.6086 6.00195 13.3992 6.17334 13.2278L10.5151 8.9939L6.17334 4.77271C6.00195 4.60132 5.90039 4.3855 5.90039 4.15063C5.90039 3.67456 6.2876 3.30005 6.76367 3.30005C6.99854 3.30005 7.19531 3.37622 7.36035 3.54126L12.248 8.32104C12.4512 8.52417 12.5464 8.73999 12.5464 9.00024Z"
              fill="white"
            />
          </svg>
        </Body>
      </StreaksButton>
    </Section>
  );
}
