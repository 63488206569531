import type { ButtonHTMLAttributes, ComponentType } from 'react';
import cn from 'classnames';

import { ReactComponent as SpinnerIcon } from '../../../svg/img/spinner24.svg';
import s from './Button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'sm' | 'md';
  variant?: 'tertiary' | 'filled';
  IconLeft?: ComponentType<{ className?: string }>;
  fullWidth?: boolean;
  shining?: boolean;
  loading?: boolean;
  muteOnDisabled?: boolean;
}

export function Button({
  size,
  IconLeft,
  variant,
  className,
  children,
  fullWidth,
  shining,
  disabled,
  loading,
  muteOnDisabled = true,
  ...rest
}: ButtonProps) {
  size ||= 'md';
  variant ||= 'filled';

  return (
    <button
      disabled={disabled}
      className={cn(
        s.root,
        s[variant],
        s[size],
        fullWidth && s.fullWidth,
        className,
        disabled
          ? muteOnDisabled
            ? s.disabled
            : undefined
          : s.active,
        loading && s.loading,
      )}
      {...rest}
    >
      {shining && (
        <div className={s.shiningRoot}>
          <div className={s.shining}/>
        </div>
      )}
      {IconLeft && <IconLeft className={s.iconLeft}/>}
      {children && <span className={s.content}>{children}</span>}
      {loading && <SpinnerIcon className={s.spinner}/>}
    </button>
  );
}