import type { ComponentType, PropsWithChildren, ReactNode } from 'react';

import { Panel } from '../../../Panel/Panel';
import { Callout } from '../../../../../../../uilib/Typography/Typography';
import { PanelContent } from '../../../PanelContent/PanelContent';

import s from './StatusPanel.module.scss';

export function StatusPanel({ title, children, button, Icon, onClose }: PropsWithChildren<{
  title: string;
  Icon: ComponentType<{ className?: string }>;
  button?: ReactNode;
  onClose?(): void;
}>) {
  const titleWords = title.split(' ');

  return (
    <Panel
      title={
        <>
          {titleWords.slice(0, titleWords.length - 1).join(' ')}{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            {titleWords[titleWords.length - 1]} <Icon className={s.icon}/>
          </span>
        </>
      }
      onClose={onClose}
    >
      <PanelContent>
        <Callout className={s.content} weight="regular">
          {children}
        </Callout>
        {button && (
          <div className={s.button}>
            {button}
          </div>
        )}
      </PanelContent>
    </Panel>
  );
}