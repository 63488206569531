import useSWR, { type SWRResponse } from 'swr';

import { getItemFromStorage, setItemToStorage } from '../../../../../../../hooks/hooks';

const STORAGE_KEY = 'streakRestorationTransactionId';

/**
 * Hook controlling the transaction ID stored in the Cloud Storage.
 */
export function useTransactionId(): [SWRResponse<number | null>, {
  set(transactionId: number | null): void;
}] {
  const response = useSWR('STREAK_RESTORATION_TRANSACTION_ID', () => {
    return getItemFromStorage(STORAGE_KEY, 5000).then(r => Number(r) || null);
  });

  return [response, {
    set(transactionId: number | null) {
      response.mutate(transactionId, { revalidate: false }).catch(console.error);
      setItemToStorage(
        STORAGE_KEY,
        transactionId ? String(transactionId) : '',
        5000,
      ).catch(console.error);
    },
  }];
}