import { useCallback, useEffect, useMemo, useState } from 'react';

type SelectedItems = {
  [CollectionId: string]: {
    item: {
      pocketKey?: string;
      limit?: number;
      price: number;
    };
    count: number;
  };
};

/**
 * Hook providing utilities to work with the selected artifacts.
 */
export function useSelectedItems(
  collections: {
    id: string;
    price: number;
    pocketType?: string;
    limit?: number;
  }[],
  pocket: Record<string, number>,
) {
  const [selected, setSelected] = useState<SelectedItems>({});

  // Whenever user pocket information changes, we should check if the currently selected items
  // count doesn't exceed items' limits.
  useEffect(() => {
    setSelected(selected => {
      return Object
        .entries(selected)
        .reduce<SelectedItems>((acc, [collectionId, selectedItem]) => {
          const { item, count } = selectedItem;

          // Item must have information about limits and pocket key, so we could determine
          // how many of this item the user already added.
          if (item.limit && item.pocketKey) {
            acc[collectionId] = {
              ...selectedItem,
              count: Math.min(count, item.limit - (pocket[item.pocketKey] || 0)),
            };
          } else {
            // In all other cases, we just leave the current selection data as-is.
            acc[collectionId] = selectedItem;
          }
          return acc;
        }, {});
    });
  }, [pocket]);

  return {
    totalPrice: useMemo(() => Object
      .values(selected)
      .reduce<number>((acc, selectedItem) => {
        return acc + selectedItem.count * selectedItem.item.price;
      }, 0), [selected]),
    changeCountSelected: useCallback((id: string, add: boolean) => {
      if (!collections) {
        return;
      }

      let current = selected[id];
      if (current) {
        if (add) {
          current.count++;
        } else {
          current.count = Math.max(current.count - 1, 0);
        }
      } else {
        current = {
          item: collections.find(item => item.id === id)!,
          count: 1,
        };
      }

      // TODO: For some reason, using setSelectedItems(state => { ... }) works improperly. Passed
      //  hook is being called twice.
      setSelected({ ...selected, [id]: current });
    }, [collections, selected]),
    getCountSelected: useCallback((id: string) => {
      return id in selected ? selected[id].count : 0;
    }, [selected]),
    getSelectedItems: useCallback(() => {
      return Object.entries(selected).reduce<{ collectionId: string; count: number }[]>(
        (acc, [collectionId, { count }]) => {
          count && acc.push({ collectionId, count });
          return acc;
        }, [],
      );
    }, [selected]),
    resetSelected: useCallback(() => {
      setSelected({});
    }, []),
  };
}