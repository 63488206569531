import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { Button, type ButtonProps } from '../Button/Button';

import { ReactComponent as FaqIcon } from '../../../svg/img/fag20.svg';
import s from './RedPacketGoToOnbButton.module.scss';

export function RedPacketGoToOnbButton({
  className,
  ...rest
}: Omit<ButtonProps, 'variant' | 'size' | 'IconLeft' | 'onClick'>) {
  const navigate = useNavigate();
  return (
    <Button
      {...rest}
      className={cn(s.root, className)}
      variant="tertiary"
      size="sm"
      IconLeft={FaqIcon}
      onClick={() => {
        navigate('/red-packet/onboarding');
      }}
    />
  );
}