import { RedPacketPanel } from '../RedPacketPanel/RedPacketPanel';

import { RedPacketTaskText } from '../RedPacketTaskText/RedPacketTaskText';
import { Trans } from 'react-i18next';
import { RedPacketTaskHeader } from '../RedPacketTaskHeader/RedPacketTaskHeader';

import { publicUrl } from '../../../utils/url';
import { createSrcSet } from '../../../utils/createSrcSet';

import { ReactComponent as AsteriskIcon } from '../../../svg/img/asterisk-8.svg';
import s from './RedPacketTaskSecret.module.scss';

export function RedPacketTaskSecret() {
  return (
    <RedPacketPanel className={s.root}>
      <RedPacketTaskHeader
        icon={{
          src: publicUrl('/icons/monkey-24/monkey-24.png'),
          srcSet: createSrcSet(['', '@2x', '@3x'].map(suffix => {
            return publicUrl(`/icons/monkey-24/monkey-24${suffix}.png`);
          })),
        }}
      >
        <div className={s.hidden}>
          <div className={s.hiddenContent}>
            {new Array(11).fill(0).map((_, i) => (
              <AsteriskIcon key={i}/>
            ))}
          </div>
        </div>
      </RedPacketTaskHeader>
      <RedPacketTaskText>
        <Trans i18nKey="RedPacketSecretTask.Text"/>
      </RedPacketTaskText>
    </RedPacketPanel>
  );
}