import type { HTMLAttributes } from 'react';
import Markdown from 'react-markdown';

import { StreaksPanel } from '../../../_components/StreaksPanel/StreaksPanel';
import { DefaultShimmer } from '../../../_components/DefaultShimmer/DefaultShimmer';
import { Body } from '../../../../uilib/Typography/Typography';

import s from './DescriptionPanel.module.scss';

function MarkdownParagraph(props: HTMLAttributes<HTMLParagraphElement>) {
  return <Body weight="regular" {...props}/>;
}

export function DescriptionPanel(props: { children?: string }) {
  return (
    <StreaksPanel className={s.root}>
      <Markdown components={{ p: MarkdownParagraph }}>
        {props.children}
      </Markdown>
    </StreaksPanel>
  );
}

DescriptionPanel.Shimmer = () => <DefaultShimmer className={s.shimmer} height={176}/>;
