import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { RedPacketInfoPageTemplate } from '../RedPacketInfoPageTemplate/RedPacketInfoPageTemplate';
import { findLeagueById } from '../../leagues/leagues';

export function RedPacketLeaguePage() {
  const searchParams = new URLSearchParams(useLocation().search);
  const days = Number(searchParams.get('days'));
  const id = Number(searchParams.get('id'));
  const league = id && findLeagueById(id);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    !league && navigate('/', { replace: true });
  }, [league, navigate]);

  return league ? (
    <RedPacketInfoPageTemplate
      mainButtonText={t('RedPacketLeaguePage.ButtonText')}
      title={t(league.redPacketOnboardingTitleKey)}
      onMainButtonClick={() => {
        navigate('/streaks', { replace: true });
      }}
      Icon={({ className }) => (
        <img
          className={className}
          src={league.img.src}
          srcSet={league.img.srcSet}
        />
      )}
      steps={[
        t('RedPacketLeaguePage.Step1'),
        t('RedPacketLeaguePage.Step2'),
        t(league.redPacketOnboardingStep3Key, { count: days }),
      ]}
    />
  ) : null;
}