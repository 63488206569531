import { useActiveOrder as _useActiveOrder } from '../../hooks/pocket/queries/useActiveOrder';

export function useActiveOrder({ hasPendingOrder, onPending, onCompleted, onFailed, onMissing }: {
  hasPendingOrder: boolean,
  onPending(): void;
  onMissing(): void;
  onCompleted(): void;
  onFailed(reason: 'unknown' | 'limit-reached'): void;
}) {
  _useActiveOrder({
    shouldCall: hasPendingOrder,
    refreshInterval: 2000,
    onSuccess(data) {
      if (data) {
        const { status } = data;
        if (status === 'COMPLETED') {
          return onCompleted();
        }

        if (status === 'PENDING') {
          return onPending();
        }

        if (status === 'FAILED') {
          onFailed(
            data.statusDetails && data.statusDetails.code === 'ORDER_LIMIT_REACHED'
              ? 'limit-reached'
              : 'unknown',
          );
        }
      }

      // In all other cases, we just stop polling.
      onMissing();
    },
    onError(error) {
      console.error('Error fetching active order', error);
    },
  });
}