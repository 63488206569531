import { useMemo } from 'react';

import { useStreaksTasks } from '../../../../hooks/streaks/useStreaksTasks';
import { useStreaksUserState } from '../../../../hooks/streaks/useStreaksUserState';
import { formatTask, FormattedTask } from '../../utils/formatTask';
import { useNow } from '../../../../hooks/dates/useNow';
import { useHiddenTasks } from '../../hooks/useHiddenTasks';
import { useIsCloudStorageLoading } from '../../../../providers/CloudStorageProvider/context';
import type { TaskData } from './Tasks/Task/Task';

export function useData(): 'loading' | undefined | TaskData[] {
  const {
    data: streaksTasks,
    isLoading: isStreaksTasksLoading,
  } = useStreaksTasks();
  const isCSLoading = useIsCloudStorageLoading();
  const [hiddenTasks] = useHiddenTasks();
  const {
    data: userState,
    isLoading: isUserStateLoading,
  } = useStreaksUserState();
  const now = useNow();

  // Retrieve additional tasks from the response.
  const formattedTasks = useMemo(() => {
    return isStreaksTasksLoading || isCSLoading
      ? 'loading'
      : streaksTasks && streaksTasks.additional
        ? streaksTasks
          .additional
          .map(formatTask)
          .filter((task): task is Exclude<typeof task, undefined> => {
            return !!task && !hiddenTasks.includes(task.id);
          })
        : undefined;
  }, [streaksTasks, isStreaksTasksLoading, isCSLoading, hiddenTasks]);

  // Leave only tasks which are active at the moment.
  const activeTasks = useMemo(() => {
    const nowTimestamp = now.getTime();
    return Array.isArray(formattedTasks)
      ? formattedTasks.filter((task): task is FormattedTask & { startsAt: Date } => {
        // fixme: should always be truthy
        return !!(
          task.startsAt
          && task.startsAt.getTime() <= nowTimestamp
          && (!task.endsAt || nowTimestamp < task.endsAt.getTime())
        );
      })
      : formattedTasks;
  }, [now, formattedTasks]);

  return useMemo(() => {
    if (activeTasks === 'loading' || isUserStateLoading) {
      return 'loading';
    }
    if (!activeTasks) {
      return;
    }

    // No user data fetched. The only thing we can do here is just to display the daily task.
    if (!userState) {
      return activeTasks.map(task => ({ ...task, isCompleted: false }));
    }

    // User didn't complete the daily task, display it.
    const { dailyCompletedTasks } = userState;
    return activeTasks.map((task) => ({
      ...task,
      isCompleted: dailyCompletedTasks.includes(task.id),
    }), []);
  }, [activeTasks, userState, isUserStateLoading]);
}