import cn from 'classnames';
import { HTMLAttributes } from 'react';

import s from './DefaultShimmer.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  width?: string | number;
  height?: string | number;
  rounded?: boolean;
  mb?: number | string;
}

export function DefaultShimmer({
  className,
  rounded,
  width,
  height,
  style,
  mb,
  ...rest
}: Props) {
  return (
    <div
      className={cn(s.root, rounded && s.rounded, className)}
      style={{ width, height, ...(mb ? { marginBottom: mb } : {}), ...style }}
      {...rest}
    />
  );
}
