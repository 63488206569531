import { Task, type TaskData } from './Task/Task';

import s from './Tasks.module.scss';

export function Tasks({ data }: { data: 'loading' | TaskData[] }) {
  return data === 'loading' ? <Task data="loading"/> : (
    <div className={s.root}>
      {data.map((item, key) => <Task data={item} key={key}/>)}
    </div>
  );
}