import { Trans, useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

import { StatusPanel } from '../StatusPanel/StatusPanel';
import { StreaksButton } from '../../../../../../_components/StreaksButton/StreaksButton';
import { useApiClient } from '../../../../../../providers/ApiClientProvider/context';
import { useStreaksUserState } from '../../../../../../hooks/streaks/useStreaksUserState';
import { useHapticFeedback } from '../../../../../../hooks/useHapticFeedback';
import { useUserPocket } from '../../../../../../hooks/pocket/queries/useUserPocket';
import { useShowAlert } from '../../../../../../hooks/useShowAlert';
import { ApiError } from '../../../../../../../shared/http/ApiError';
import { Body } from '../../../../../../../uilib/Typography/Typography';

import { ReactComponent as WarningIcon } from '../../../../../../../svg/img/warning.svg';
import { ReactComponent as SpinnerIcon } from '../../../../../../../svg/img/spinner24.svg';
import s from './StreakLost.module.scss';

export function StreakLost() {
  const client = useApiClient();
  const userStreakState = useStreaksUserState();
  const { t } = useTranslation();
  const haptic = useHapticFeedback();
  const userPocket = useUserPocket();
  const showAlert = useShowAlert();

  const { isMutating, trigger: resetStreak } = useSWRMutation(
    'api.resetStreak',
    client.resetStreak.bind(client), {
      onError(err) {
        haptic.notificationOccurred('error');
        console.error('Error resetting the streak', err);

        // Our local state desynced with the server one for some reason.
        if (err instanceof ApiError && err.hasErrorCode('ERROR_STREAK_CAN_BE_PROTECTED')) {
          void userPocket.mutate();
          void userStreakState.mutate();
          return;
        }
        showAlert(t('StreaksPage.AlertStreakResetUnknownError'));
      },
      onSuccess() {
        void userStreakState.mutate();
      },
    },
  );

  return (
    <StatusPanel
      title={t('StreaksPage.StatusPanelTitle.StreakLost')}
      Icon={({ className }) => (
        <WarningIcon
          className={className}
          style={{ color: '#FF3B30' }}
        />
      )}
      button={
        <StreaksButton
          className={s.button}
          disabled={isMutating}
          onClick={() => {
            resetStreak();
          }}
        >
          <Body weight="semi-bold">
            <Trans
              i18nKey={
                isMutating
                  ? 'StreaksPage.StatusPanelButton.ResettingStreak'
                  : 'StreaksPage.StatusPanelButton.StreakLost'}
            />
            {isMutating && (
              <span className={s.spinner}>
                <SpinnerIcon className={s.spinnerIcon}/>
              </span>
            )}
          </Body>
        </StreaksButton>
      }
    >
      <Trans i18nKey="StreaksPage.StatusPanelText.StreakLost"/>
    </StatusPanel>
  );
}
