import { Trans } from 'react-i18next';

import { StreaksTimer } from '../StreaksTimer/StreaksTimer';
import { useNow } from '../../hooks/dates/useNow';
import { useUTCToday } from '../../hooks/dates/useUTCToday';

export function StreaksTimerDaily() {
  const now = useNow();
  const today = useUTCToday();

  return (
    <StreaksTimer from={today} now={now} to={today + 24 * 60 * 60 * 1000}>
      <Trans i18nKey="StreaksTimer.Text"/>
    </StreaksTimer>
  );
}