import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { State } from '../State/State';
import { createImage } from '../createImage';
import { Body, Callout } from '../../../../uilib/Typography/Typography';
import { RedPacketPanel } from '../../RedPacketPanel/RedPacketPanel';
import { RedPacketTaskHeader } from '../../RedPacketTaskHeader/RedPacketTaskHeader';
import { publicUrl } from '../../../../utils/url';
import { createSrcSet } from '../../../../utils/createSrcSet';
import { RedPacketTaskText } from '../../RedPacketTaskText/RedPacketTaskText';
import { RedPacketButton } from '../../RedPacketButton/RedPacketButton';
import { useIsRedPacketOver } from '../../../hooks/flags/useIsRedPacketOver';
import {
  useRedPacketUnopenedPacketsCount,
} from '../../../hooks/red-packet/useRedPacketUnopenedPacketsCount';

import s from './ErrorState.module.scss';

export type ErrorStateType =
  | 'packetNotFound'
  | 'packetAlreadyOpened'
  | 'packetFromSameUser'
  | 'packetExpired'
  | 'unknown';

export function ErrorState({ type, shared }: { type: ErrorStateType; shared: boolean }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isRedPacketOver = useIsRedPacketOver();
  const unopenedPacketsCount = useRedPacketUnopenedPacketsCount();
  const hasUnopenedPackets = !!(typeof unopenedPacketsCount === 'number' && unopenedPacketsCount);
  const image = createImage('error', { height: '120%', y: -25 });

  const { SecondaryButton: sb, themeParams: tp } = window.Telegram.WebApp;

  useEffect(() => {
    if (shared && !isRedPacketOver) {
      return;
    }

    const onSbClick = () => {
      navigate(-1);

      if (isRedPacketOver && !hasUnopenedPackets) {
        navigate(shared ? '/' : '/streaks', { replace: true });
      }
    };
    const timeoutId = setTimeout(() => {
      sb.onClick(onSbClick);
      sb.setParams({
        is_active: true,
        is_visible: true,
        text: t(isRedPacketOver
          ? hasUnopenedPackets
            ? 'RedPacketOpenPacketPage.OpenMore'
            : shared
              ? 'RedPacketOpenPacketPage.GoToMain'
              : 'RedPacketOpenPacketPage.GoToStreaks'
          : 'RedPacketOpenPacketPage.BackToTasks'),
        color: '#372A2E',
        text_color: '#ffffff',
      });
    }, 2000);

    return () => {
      sb.offClick(onSbClick);
      clearTimeout(timeoutId);
    };
  }, [t, navigate, type, sb, shared, isRedPacketOver, hasUnopenedPackets]);

  useEffect(() => {
    return () => {
      sb.setParams({
        is_visible: false,
        color: tp.button_color,
        text_color: tp.button_text_color,
      });
    };
  }, [sb, tp]);

  return (
    <State
      title={t(({
        packetNotFound: 'RedPacketOpenPacketPage.ErrorNotFound',
        packetAlreadyOpened: 'RedPacketOpenPacketPage.ErrorAlreadyOpened',
        packetFromSameUser: 'RedPacketOpenPacketPage.ErrorFromSameUser',
        packetExpired: 'RedPacketOpenPacketPage.ErrorExpired',
        unknown: 'RedPacketOpenPacketPage.ErrorUnknown',
      } satisfies Record<ErrorStateType, string>)[type])}
      {...image}
    >
      {type === 'unknown' && (
        <Callout className={s.subtitle} weight="regular">
          {t('RedPacketOpenPacketPage.ErrorStateText')}
        </Callout>
      )}
      {shared && !isRedPacketOver && (
        <div className={s.container}>
          <RedPacketPanel className={s.panel}>
            <RedPacketTaskHeader
              icon={{
                src: publicUrl('/red-packet/icon/icon.png'),
                srcSet: createSrcSet(['', '@2x', '@3x'].map(suffix => {
                  return publicUrl(`/red-packet/icon/icon${suffix}.png`);
                })),
              }}
            >
              {t('RedPacketOpenPacketPage.ErrorStatePanelTitle')}
            </RedPacketTaskHeader>
            <RedPacketTaskText>
              {t('RedPacketOpenPacketPage.ErrorStatePanelText')}
            </RedPacketTaskText>
            <div className={s.footer}>
              <RedPacketButton
                color="yellow"
                onClick={() => {
                  navigate(-1);
                }}
                fullWidth
                shining
              >
                <Body weight="semi-bold">
                  {t('RedPacketOpenPacketPage.ErrorStatePanelButton')}
                </Body>
              </RedPacketButton>
            </div>
          </RedPacketPanel>
        </div>
      )}
    </State>
  );
}