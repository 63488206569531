import useSWR, { type SWRConfiguration, type SWRResponse } from 'swr';

import { useApiClient } from '../../../providers/ApiClientProvider/context';
import type { GetActiveOrderResponse } from '../../../helpers/types';

export type ActiveOrderSWRResponse = SWRResponse<GetActiveOrderResponse['data'], unknown>;

interface Options extends SWRConfiguration<GetActiveOrderResponse['data'], unknown> {
  shouldCall?: boolean;
}

/**
 * Requests information on the currently active order.
 * @param options - additional SWR options.
 */
export function useActiveOrder(options?: Options): ActiveOrderSWRResponse {
  options ||= {};
  const { shouldCall } = options;
  const client = useApiClient();

  return useSWR<GetActiveOrderResponse['data']>(
    typeof shouldCall === 'undefined' || shouldCall ? 'ACTIVE_ORDER' : null,
    () => client.getActiveOrder().then(r => r.data),
    {
      ...options,
      onError(err, key, config) {
        console.error('Error fetching active order', err);
        options && options.onError && options.onError(err, key, config);
      },
    },
  );
}