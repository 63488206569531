import React from 'react';
import ReactDOM from 'react-dom/client';
import { retrieveLaunchParams } from '@telegram-apps/sdk-react';

import { applyPolyfills } from './applyPolyfills';
import { applyWindowUtils } from './applyWindowUtils';
import { initSentry } from './utils/init/sentry';
import { processStartParamAsDeeplink } from './processStartParamAsDeeplink';
import { Root } from './app/_components/Root';

// Mock the environment in case, we are outside Telegram.
import './mockEnv.ts';

applyWindowUtils();
applyPolyfills();

try {
  if (
    process.env.NODE_ENV === 'development'
    || retrieveLaunchParams().startParam === 'debug'
  ) {
    // Add Eruda if needed.
    import('eruda')
      .then(({ default: eruda }) => {
        eruda.init();
        eruda.position({ x: 10, y: 100 });
      })
      .catch(console.error);
  }
} catch (e) {
}

initSentry();

const tonConnectReturnBaseUrl = process.env.REACT_APP_TON_CONNECT_RETURN_BASE_URL;
// TODO: This should not work like that. There should be a proper error handling.
if (!tonConnectReturnBaseUrl) {
  throw new Error('REACT_APP_TON_CONNECT_RETURN_BASE_URL is missing');
}

const tonConnectManifestUrl = process.env.REACT_APP_TON_CONNECT_MANIFEST_URL;
// TODO: This should not work like that. There should be a proper error handling.
if (!tonConnectManifestUrl) {
  throw new Error('REACT_APP_TON_CONNECT_MANIFEST_URL is missing');
}

const unleashClientKey = process.env.REACT_APP_UNLEASH_CLIENT_KEY;
if (!unleashClientKey) {
  throw new Error('REACT_APP_UNLEASH_CLIENT_KEY is missing');
}

const unleashUrl = process.env.REACT_APP_UNLEASH_URL;
if (!unleashUrl) {
  throw new Error('REACT_APP_UNLEASH_URL is missing');
}

processStartParamAsDeeplink(
  new URLSearchParams(window.location.search)
    .get('tgWebAppStartParam') || '',
);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Root
      // apiBaseUrl={new URL('https://staging.tappscenter.org/api/', window.location.href).toString()}
      apiBaseUrl={new URL('/api/', window.location.href).toString()}
      tonConnectReturnBaseUrl={tonConnectReturnBaseUrl}
      tonConnectManifestUrl={tonConnectManifestUrl}
      unleashUrl={unleashUrl}
      unleashClientKey={unleashClientKey}
    />
  </React.StrictMode>,
);
