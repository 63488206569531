export function copyToClipboard(text: string): Promise<string> {
    return new Promise((resolve, reject) => {
        navigator.clipboard.writeText(text).then(() => {
            resolve(text);
        }).catch((e) => {
            copyToClipboardFallback(text).then(resolve).catch(reject);
        });
    });
}

export function copyToClipboardFallback(text: string): Promise<string> {
    return new Promise((resolve, reject) => {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
            document.execCommand('copy');
            resolve(text);
        } catch (e) {
            reject(e);
        } finally {
            document.body.removeChild(textarea);
        }
    });
}
