import { useTranslation } from 'react-i18next';

import { usePocketCollections } from '../../hooks/pocket/queries/usePocketCollections';
import { findArtifact } from '../../pocket/artifacts';
import { useUserPocket } from '../../hooks/pocket/queries/useUserPocket';

export function useCollection(id: string) {
  const { t } = useTranslation();
  const { data: userPocket } = useUserPocket();
  const { data: collections } = usePocketCollections();

  if (userPocket && id === 'USDT') {
    const artifact = findArtifact('USDT');
    return artifact ? {
      id: 'USDT',
      title: t(artifact.titleKey),
      subtitle: t(artifact.shortDescriptionKey, { count: userPocket.usdt || 0 }),
      description: t(artifact.descriptionKey),
      price: 0,
      img: artifact.img.large,
      pocketType: artifact.pocketType,
    } : undefined;
  }

  if (collections) {
    for (const item of collections) {
      if (item.collectionId === id) {
        const artifact = findArtifact(item.type);
        return artifact ? {
          id: item.collectionId,
          title: t(artifact.titleKey),
          subtitle: t(artifact.shortDescriptionKey),
          description: t(artifact.descriptionKey),
          price: item.price,
          img: artifact.img.large,
          limit: artifact.limit,
          limitKey: artifact.limitKey,
          pocketType: artifact.pocketType,
        } : undefined;
      }
    }
  }
}