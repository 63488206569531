import cn from 'classnames';

import { Button, type ButtonProps } from '../Button/Button';

import s from './RedPacketButton.module.scss';

export interface RedPacketButtonProps extends ButtonProps {
  color?: 'yellow' | 'red';
}

export function RedPacketButton({ color, ...rest }: RedPacketButtonProps) {
  return <Button {...rest} className={cn(s.root, color && s[color])}/>;
}