import { object, string } from 'yup';

export class ApiError<D = unknown> extends Error {
  constructor(
    public readonly status: number,
    public readonly statusText: string,
    public readonly data: D,
  ) {
    super(`HTTP Error: ${status} ${statusText}`);
    this.name = 'ApiError';
  }

  /**
   * States that the current error data contains the string "errorCode" property.
   */
  hasErrorCodeProp(): this is ApiError<D & { errorCode: string }> {
    return object({ errorCode: string().required() }).isValidSync(this.data);
  }

  /**
   * @returns True if the current error has the specified error code.
   * @param errorCode - error code.
   */
  hasErrorCode(errorCode: string): boolean {
    return this.hasErrorCodeProp() && this.data.errorCode === errorCode;
  }

  /**
   * @returns True if the current error has one of the specified error codes.
   * @param errorCodes - error codes.
   */
  hasOneOfErrorCodes<T extends string>(errorCodes: T[]): boolean {
    return this.hasErrorCodeProp() && errorCodes.some(errCode => this.hasErrorCode(errCode));
  }
}
