import { useTranslation } from 'react-i18next';

import { RedPacketTask, RedPacketTaskProps } from '../RedPacketTask/RedPacketTask';

import { ReactComponent as PeopleAnnounceIcon } from '../../../svg/img/friends-24.svg';

export function RedPacketTaskInviteFriends({ count, ...rest }: {
  count: number
} & Pick<RedPacketTaskProps, 'id' | 'onHide'>) {
  const { t } = useTranslation();
  return (
    <RedPacketTask
      {...rest}
      type="completable"
      title={t('RedPacketInviteFriendsTask.Title', { count })}
      text={t('RedPacketInviteFriendsTask.Text')}
      headerIcon={PeopleAnnounceIcon}
      infoPath={`/red-packet/invite?count=${count}`}
    />
  );
}