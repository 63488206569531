import type { ComponentType, PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';

import { Caption, SubHeadline } from '../../../uilib/Typography/Typography';

import { ReactComponent as ChevronIcon } from '../../../svg/img/chevron-right18.svg';
import s from './Callout.module.scss';

export function Callout({ className, title, children, Icon, type, onClick }: PropsWithChildren<{
  className?: string;
  title: ReactNode;
  Icon: ComponentType<{ className?: string }>;
  type?: 'info' | 'warning';
  onClick?(): void;
}>) {
  type ||= 'info';

  return (
    <div
      className={cn(
        s.root,
        s[`root${type[0].toUpperCase() + type.slice(1)}`],
        onClick && s.clickable,
        className
      )}
      onClick={onClick}
    >
      <Icon className={s.icon}/>
      <div className={s.texts}>
        <SubHeadline className={s.title} weight="semi-bold" level={1}>
          {title}
        </SubHeadline>
        <Caption className={s.text} weight="regular" level={1}>
          {children}
        </Caption>
      </div>
      {onClick && <ChevronIcon className={s.arrow}/>}
    </div>
  );
}