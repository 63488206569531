import { type PropsWithChildren, useCallback, useEffect, useMemo } from 'react';

import { ServerTimeContext, type ServerTimeContextType } from './context';
import useSWRImmutable from 'swr/immutable';

export function ServerTimeProvider(props: PropsWithChildren) {
  const { isLoading, data, mutate } = useSWRImmutable('telegram.serverTimeDiff', () => {
    const now = (Date.now() / 1000) | 0;

    return Promise.race([
      new Promise<number>((resolve, reject) => {
        // @ts-ignore
        Telegram.WebApp.invokeCustomMethod('getCurrentTime', {}, (err, time) => {
          if (err) {
            reject(err);
          } else {
            resolve(now - time);
          }
        });
      }),
      new Promise<never>((_, rej) => {
        setTimeout(() => rej(new Error('Timed out')), 5000);
      }),
    ]);
  }, {
    onError(err) {
      console.error('Failed to load Telegram server time:', err);
    },
  });

  const getCurrentTime = useCallback(() => {
    const diff = data || 0;
    return new Date(new Date().getTime() - diff * 1000);
  }, [data]);

  const context = useMemo<ServerTimeContextType>(() => ({
    isLoading,
    getCurrentTime,
  }), [getCurrentTime, isLoading]);

  useEffect(() => {
    (window as any).__setCurrentTime = (date: Date) => {
      mutate((Date.now() - date.getTime()) / 1000 | 0, { revalidate: false });
    };
    return () => {
      delete (window as any).__setCurrentTime;
    };
  }, [mutate]);

  return <ServerTimeContext.Provider {...props} value={context}/>;
}