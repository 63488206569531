import i18n from 'i18next';
import { useState, type PropsWithChildren, useMemo, useEffect, useCallback } from 'react';
import useSWRImmutable from 'swr/immutable';

import { setItemToStorage } from '../../../hooks/hooks';
import { initI18n, LOCALES } from './i18n';
import { LocaleContext } from './context';

export function LocaleProvider(props: PropsWithChildren) {
  const [locale, setLocale] = useState(i18n.language as LOCALES | undefined);
  const { isLoading } = useSWRImmutable('l10n', initI18n);

  const changeLocale = useCallback((value: LOCALES) => {
    i18n.changeLanguage(value);
    setItemToStorage('locale', value);
    setLocale(value);
  }, []);
  const context = useMemo(() => ({
    changeLocale,
    locale,
    isLoading,
  }), [isLoading, locale, changeLocale]);

  useEffect(() => {
    // @ts-ignore Exposing it for test purposes.
    window.__changeLocale = changeLocale;
    return () => {
      delete (window as any)['__changeLocale'];
    };
  }, [changeLocale]);

  // Whenever the i18n initialized, update the locale value.
  useEffect(() => {
    if (!isLoading) {
      setLocale(i18n.language as LOCALES);
    }
  }, [isLoading]);

  return <LocaleContext.Provider {...props} value={context}/>;
}

