import { Trans } from 'react-i18next';
import type { ReactNode } from 'react';

import { Callout } from '../Callout/Callout';

import { ReactComponent as PendingIcon } from '../../../svg/img/pending32.svg';

export function NonImmediateTransferCallout({
  title,
  text,
  ...rest
}: {
  className?: string;
  title?: ReactNode;
  text?: ReactNode;
}) {
  return (
    <Callout
      {...rest}
      Icon={PendingIcon}
      title={title || <Trans i18nKey="NonImmediateTransferCallout.Title"/>}
    >
      {text && <Trans i18nKey="NonImmediateTransferCallout.Text"/>}
    </Callout>
  );
}