import { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

export interface ToastProps {
    type: "success" | "error";
}

export const Toast: React.FC<PropsWithChildren<ToastProps>> = ({
    children,
    type,
}) => {
    return (
        <div className={styles.toast}>
            {type === "success" ? (
                <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14 25C20.0751 25 25 20.0751 25 14C25 7.92487 20.0751 3 14 3C7.92487 3 3 7.92487 3 14C3 20.0751 7.92487 25 14 25ZM19.1826 9.91716C19.413 9.54016 19.2942 9.04777 18.9172 8.81737C18.5402 8.58698 18.0478 8.70584 17.8174 9.08284L12.8851 17.1538L10.1021 13.9732C9.81112 13.6407 9.30571 13.607 8.9732 13.8979C8.64069 14.1889 8.60699 14.6943 8.89794 15.0268L12.3979 19.0268C12.5656 19.2184 12.8144 19.3188 13.068 19.2971C13.3217 19.2755 13.5499 19.1344 13.6826 18.9172L19.1826 9.91716Z"
                            fill="#30D158"
                        />
                    </g>
                </svg>
            ) : null}
            {children}
        </div>
    );
};
