import { useCallback, useEffect, useRef, useState } from 'react';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';

import { useApiClient } from '../../../providers/ApiClientProvider/context';
import { State } from '../State/State';
import { createImage } from '../createImage';
import { Progressbar } from '../../Progressbar/Progressbar';
import { useRedPacketState } from '../../../hooks/red-packet/useRedPacketState';
import type { RedPacketTaskPacketType } from '../../../helpers/types';

import s from './OpeningState.module.scss';

export function OpeningState({ packet, onCompleted, onError }: {
  packet: ['hash', string] | ['task', number, string?];
  onError(error: unknown): void;
  onCompleted(data: {
    type: RedPacketTaskPacketType;
    value: number;
  }): void;
}) {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const [progressReady, setProgressReady] = useState(false);

  const client = useApiClient();
  const { mutate: mutateState } = useRedPacketState();
  const {
    trigger: openPacket,
    error,
    data,
  } = useSWRMutation(
    'OPEN_RED_PACKET',
    (_, { arg }: { arg: ['hash', string] | ['task', number, string?] },
    ) => {
      return arg[0] === 'hash'
        ? client.openSharedRedPacketTaskPacket(arg[1])
        : client.openRedPacketTaskPacket(arg[1], arg[2]).then(({ boxToOpen }) => {
          return boxToOpen
            ? { type: boxToOpen.type, value: boxToOpen.value }
            : { type: 'unknown', value: 0 };
        });
    },
    {
      onSuccess() {
        mutateState();
      },
    },
  );

  const timeouts = useRef<number[]>([]);
  const registerTimeout = useCallback((fn: () => any, timeout: number) => {
    timeouts.current.push(window.setTimeout(fn, timeout));
  }, []);

  useEffect(() => {
    const { current: timeoutIds } = timeouts;
    return () => {
      timeoutIds.forEach(clearTimeout);
    };
  }, []);

  useEffect(() => {
    openPacket(packet);
  }, [openPacket, packet]);

  useEffect(() => {
    registerTimeout(() => {
      registerTimeout(() => {
        setProgress(20);
        registerTimeout(() => {
          setProgress(80);
          registerTimeout(() => {
            setProgressReady(true);
          }, 1000);
        }, 2000);
      }, 1500);
    }, 1200);
  }, [registerTimeout]);

  useEffect(() => {
    if (progressReady && (data || error)) {
      setProgress(100);
      registerTimeout(() => {
        data ? onCompleted(data) : onError(error);
      }, 500);
    }
  }, [progressReady, registerTimeout, data, error, onCompleted, onError]);

  return (
    <State
      {...createImage('opening', { height: '120%', x: -20, y: -15 })}
      title={t('RedPacketOpenPacketPage.OpeningTitle')}
    >
      <div className={s.progress}>
        <Progressbar
          from={0}
          current={progress}
          to={100}
          footer={{ left: t('RedPacketOpenPacketPage.ProgressbarTitle') }}
        />
      </div>
    </State>
  );
}