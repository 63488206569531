import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import { Section } from '../Section/Section';
import { Caption } from '../../../../../uilib/Typography/Typography';
import { StreaksPocketPanel } from '../../../../_components/StreaksPocketPanel/StreaksPocketPanel';
import {
  useUserPocketPoints,
} from '../../../../hooks/pocket/useUserPocketPoints';
import { DefaultShimmer } from '../../../../_components/DefaultShimmer/DefaultShimmer';
import { useTonConnect } from '../../../../providers/TonConnectProvider/context';
import { useLeaderboardRank } from './useLeaderboardRank';

import { ReactComponent as PocketPanelBg } from './pocket-panel-bg.svg';
import s from './PocketPanels.module.scss';

export function PocketPanels() {
  const { t } = useTranslation();
  const {
    modal: { open: openModal },
    wallet,
    disconnectWallet,
  } = useTonConnect();

  const shortenedWalletAddress = useMemo(() => {
    if (!wallet) {
      return;
    }
    const { address } = wallet;
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  }, [wallet]);

  const points = useUserPocketPoints();
  const {
    data: leaderboardRank,
    isLoading: isLeaderboardRankLoading,
  } = useLeaderboardRank();

  const showWalletDisconnectModal = useCallback(() => {
    if (!shortenedWalletAddress) {
      return;
    }
    window.Telegram.WebApp.showPopup({
      title: t('StreaksPage.DisconnectWalletPopupTitle'),
      message: t('StreaksPage.DisconnectWalletPopupMessage', {
        address: shortenedWalletAddress,
      }),
      buttons: [
        { id: 'disconnect', type: 'destructive', text: 'Disconnect' },
        { type: 'cancel' },
      ],
    }, (buttonId) => {
      if (buttonId === 'disconnect') {
        disconnectWallet();
      }
    });
  }, [disconnectWallet, t, shortenedWalletAddress]);

  return (
    <Section className={s.root}>
      <StreaksPocketPanel
        className={s.panel}
        title={t('StreaksPage.PocketPanelPocketTitle')}
        text={t('StreaksPage.PocketPanelPocketText')}
        link="/pocket"
        background={{
          position: { bottom: 6, right: 8 },
          node: <PocketPanelBg/>,
        }}
      />
      <StreaksPocketPanel
        className={s.panel}
        title={t('StreaksPage.PocketPanelFortuneWheelTitle')}
        text={t('StreaksPage.PocketPanelFortuneWheelText')}
        background={{
          className: s.soonWrap,
          node: (
            <Caption className={s.soon} weight="semi-bold" level={2} caps>
              <Trans i18nKey="StreaksPage.PocketPanelFortuneSoon"/>
            </Caption>
          ),
        }}
      />
      {(typeof points === 'number' || points === 'loading') && (
        <StreaksPocketPanel
          className={s.panel}
          title={typeof points === 'number'
            ? t('StreaksPage.PocketPanelCurrentPointsTitle', { count: points })
            : <DefaultShimmer height={24} width={'50%'}/>}
          text={
            isLeaderboardRankLoading
              ? <DefaultShimmer height={20} width={'100%'}/>
              : leaderboardRank
                ? t('StreaksPage.PocketPanelCurrentPointsText', { count: leaderboardRank.rank })
                : null
          }
          // link="/pocket"
        />
      )}
      <StreaksPocketPanel
        className={s.panel}
        title={t(`StreaksPage.PocketPanelConnectWalletTitle${wallet ? '.Connected' : ''}`)}
        text={shortenedWalletAddress
          ? shortenedWalletAddress
          : t('StreaksPage.PocketPanelConnectWalletText')}
        highlight={!wallet}
        onClick={wallet ? showWalletDisconnectModal : openModal}
        background={wallet ? {
          position: { right: 9, bottom: 11 },
          node: <div className={s.walletConnected}/>,
        } : undefined}
      />
    </Section>
  );
}
