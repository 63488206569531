import type { HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';

import { Heading } from '../../../../../uilib/Typography/Typography';
import { PanelContent } from '../PanelContent/PanelContent';
import { Ripple } from '../../../../../uilib/Ripple/Ripple';

import { ReactComponent as CloseIcon } from '../../../../../svg/img/close-circle.svg';
import {
  ReactComponent as ArrowRightRoundedIcon,
} from '../../../../../svg/img/arrow-right-rounded.svg';
import s from './Panel.module.scss';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: ReactNode;
  onClose?(): void;
  onRoute?(): void;
}

export function Panel({ children, title, onClose, className, onRoute, ...rest }: Props) {
  return (
    <div className={cn(s.root, className)} {...rest}>
      {!!(title || onClose) && (
        <PanelContent>
          <div className={s.header}>
            {title && (
              <Heading className={s.title} weight="bold" type="h4">
                {title}
              </Heading>
            )}
            {(onClose || onRoute) && (
              <Ripple className={s.action} onClick={onClose || onRoute}>
                {onClose
                  ? <CloseIcon className={s.actionIcon}/>
                  : <ArrowRightRoundedIcon className={s.actionIcon}/>}
              </Ripple>
            )}
          </div>
        </PanelContent>
      )}
      {children}
    </div>
  );
}