import type { PropsWithChildren } from 'react';

import { Callout } from '../../../uilib/Typography/Typography';
import { DefaultShimmer } from '../DefaultShimmer/DefaultShimmer';

import s from './RedPacketTaskText.module.scss';

export function RedPacketTaskText(props: PropsWithChildren) {
  return <Callout className={s.root} weight="regular" {...props}/>;
}

export const RedPacketTaskTextShimmer = () => (
  <div className={s.root}>
    <DefaultShimmer height={20} width={'80%'} style={{ marginBottom: 4 }}/>
    <DefaultShimmer height={20} width={'40%'}/>
  </div>
);